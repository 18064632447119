import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { BillingPlanState } from 'services/api/core/billing/enums';
import { BillingPlanFormSchema } from './BillingPlanFormSchema';

interface IBillingPlanForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

const STATUSES = [
  {
    value: BillingPlanState.PENDING,
    label: 'Чернетка',
  },
  {
    value: BillingPlanState.ACTIVE,
    label: 'Активний',
  },
  {
    value: BillingPlanState.DELETED,
    label: 'Видалений',
  },
];

export const BillingPlanForm: FC<IBillingPlanForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(BillingPlanFormSchema),
      }}
    >
      <Row gutter={10}>
        <Col span={4}>
          <Field.Uploader name="picture" label={formatMessage({ id: 'billing.plan.picture' })} />
        </Col>
        <Col span={10}>
          <Field.Input name="title" label={formatMessage({ id: 'billing.plan.title' })} />
          <Row gutter={10}>
            <Col span={12}>
              <Field.Input name="price" label={formatMessage({ id: 'billing.plan.price' })} />
            </Col>
            <Col span={12}>
              <Field.Input
                name="billingPeriod"
                label={formatMessage({ id: 'billing.plan.period' })}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Field.Select
                name="state"
                label={formatMessage({ id: 'billing.plan.state' })}
                placeholder="Оберіть статус"
                options={STATUSES}
              />
            </Col>
            <Col span={12}>
              <Field.Input
                name="sortOrder"
                label={formatMessage({ id: 'billing.plan.sort-order' })}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={8}>
              <Field.Checkbox
                name="isPayPerTeminal"
                label={formatMessage({ id: 'billing.plan.pay-per-terminal' })}
              />
            </Col>
            <Col span={8}>
              <Field.Checkbox
                name="isRecurrent"
                label={formatMessage({ id: 'billing.plan.recurrent' })}
              />
            </Col>
            <Col span={8}>
              <Field.Checkbox
                name="isVisible"
                label={formatMessage({ id: 'billing.plan.visibility' })}
              />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Field.TextArea
            name="description"
            label={formatMessage({ id: 'billing.plan.description' })}
            rows={15}
          />
        </Col>
      </Row>

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
