import styles from './ImageIcon.module.scss';

export interface IImageIconProps {
  src: string;
  size?: number;
  alt?: string;
}

export const ImageIcon = ({ size = 24, src, alt }: IImageIconProps) => (
  <div className={styles.icon}>
    <img alt={alt} src={src} height={size} width={size} />
  </div>
);
