import cs from 'classnames';
import { Button, ButtonIcon, Hint, Icon, Inline, Paragraph, Title, UAH } from '@smart-kasa/ui';

import { IBillingPlan } from 'services/api/core/billing/types';
import styles from './BillingPlan.module.scss';
import { BillingPlanState } from 'services/api/core/billing/enums';

export type BillingPlanProps = {
  plan: IBillingPlan;
  children?: never;
  onClick?: (plan: IBillingPlan) => void;
  onDelete?: (plan: IBillingPlan) => void;
};

export const BillingPlan = ({ plan, onClick, onDelete }: BillingPlanProps) => (
  <div
    className={cs(styles.plan, {
      [styles.disabled]: plan.state !== BillingPlanState.ACTIVE || !plan.isVisible,
    })}
  >
    <div className={cs(styles.row, styles.title)}>
      <Title variant="h2">{plan.title}</Title>
    </div>
    <div className={styles.row}>
      <UAH value={plan.price} decreaseHundredths className={styles.price} /> /{' '}
      {plan.isRecurrent ? 'місяць' : `${plan.billingPeriod} днів`}
      <Paragraph>За одну касу</Paragraph>
    </div>
    <div className={cs(styles.row, styles.description)}>
      <Hint margin={0}>{plan.description}</Hint>
    </div>
    <Inline className={styles.actions}>
      {onClick && (
        <Button color="dark" width="full" onClick={() => onClick(plan)}>
          Редагувати
        </Button>
      )}

      {onDelete && (
        <ButtonIcon color="light" onClick={() => onDelete(plan)}>
          <Icon name="trash" />
        </ButtonIcon>
      )}
    </Inline>
  </div>
);
