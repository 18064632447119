import { useNavigate } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Avatar, AvatarItem, DataTable, IDataTableColumn, Page, Pagination } from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';
import { useGetUsersQuery } from 'services/api/core/user/api';
import { IAMUser } from 'services/api/core/user/types';

export const UserList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange, onSortChange } = usePagination();
  const { data: users, isLoading } = useGetUsersQuery(query);

  const columns: IDataTableColumn<IAMUser>[] = [
    {
      key: 'name',
      title: formatMessage({ id: 'user.name' }),
      render: (name, { id, avatar }) => (
        <AvatarItem
          avatar={<Avatar src={avatar?.url} name={name} />}
          primaryText={name}
          secondaryText={`#${id}`}
        />
      ),
    },
    {
      key: 'phoneNumber',
      title: formatMessage({ id: 'user.phoneNumber' }),
    },
    {
      key: 'email',
      title: formatMessage({ id: 'user.email' }),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'user.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
  ];

  return (
    <Page title="Користувачі порталу">
      <DataTable
        rowIdKey="id"
        data={users?.data}
        columns={columns}
        isLoading={isLoading}
        sortMode={query.sortMode as any}
        sortColumn={query.sortColumn as any}
        onRowClick={({ id }) => navigate(`${id}`)}
        onSortChange={onSortChange}
      />
      <Pagination
        totalItems={users?.meta?.totalCount}
        currentPage={users?.meta?.currentPage}
        perPage={users?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Page>
  );
};
