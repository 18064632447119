import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';

import type { ISetting } from './types';

export const settingsApi = coreApi.enhanceEndpoints({ addTagTypes: ['Settings'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<CoreResponse<ISetting>, void>({
      query: () => ({
        url: 'core/manage/settings',
      }),
      providesTags: ['Settings'],
    }),
    update: builder.mutation<ISetting, Partial<ISetting>>({
      query: (body) => ({
        url: `core/manage/settings`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGetAllQuery, useUpdateMutation } = settingsApi;
