import React, { ReactNode } from 'react';

import { IAdmin } from 'services/api/auth/types';
import { useGetProfileQuery } from 'services/api/admin/api';

interface AdminContextProps {
  profile?: IAdmin;
  refresh: () => void;
}

export const AdminContext = React.createContext<AdminContextProps>({} as any);

export const AdminContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: user, refetch } = useGetProfileQuery();

  return (
    <AdminContext.Provider value={{ profile: user?.data, refresh: refetch }}>
      {children}
    </AdminContext.Provider>
  );
};
