import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Page, Row } from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import { useDeletePlanMutation, useGetPlansQuery } from 'services/api/core/billing/api';
import { IBillingPlan } from 'services/api/core/billing/types';

import { BillingPlan } from '../../components/BillingPlan';
import styles from './BillingPlanList.module.scss';

export const BillingPlanList = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { confirm } = useConfirm();
  const { data: plans } = useGetPlansQuery({
    skipPagination: true,
    sortColumn: 'state',
    sortMode: 'desc',
  });
  const [remove] = useDeletePlanMutation();

  const handleDelete = useCallback(
    async (plan: IBillingPlan) => {
      const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

      if (isConfirmed) remove(plan.id);
    },
    [confirm, remove, formatMessage]
  );

  return (
    <Page
      title="Тарифні плани"
      actions={
        <Button
          color="dark"
          rightIcon={<Icon name="plus" size="small" />}
          onClick={() => navigate('new')}
        >
          Створити
        </Button>
      }
    >
      <Row className={styles.wrapper}>
        {plans?.data.map((plan) => (
          <BillingPlan
            key={plan.id}
            plan={plan}
            onClick={() => navigate(`${plan.id}`)}
            onDelete={handleDelete}
          />
        ))}
      </Row>
    </Page>
  );
};
