import { useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Col, Page, Preloader, Row } from '@smart-kasa/ui';

import { BackLink, Caption } from 'components';

import { useFindMessageQuery } from 'services/api/core/feedback/api';

export const FeedbackShow = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { data: record } = useFindMessageQuery(Number(id));
  const feedback = record?.data;

  if (!feedback) return <Preloader />;

  return (
    <Page
      title={
        <BackLink>
          {feedback.name} / Звернення #{feedback.id}
        </BackLink>
      }
    >
      <Row gutter={10}>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'feedback.name' })}>{feedback.name}</Caption>
          <Caption name={formatMessage({ id: 'feedback.email' })}>{feedback.email}</Caption>
          <Caption name={formatMessage({ id: 'feedback.phone' })}>{feedback.phoneNumber}</Caption>
          <Caption name={formatMessage({ id: 'feedback.createdAt' })}>
            <FormattedDate value={feedback.createdAt} dateStyle="short" timeStyle="short" />
          </Caption>
        </Col>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'feedback.content' })}>{feedback.content}</Caption>
        </Col>
      </Row>
    </Page>
  );
};
