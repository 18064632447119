import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Card, PageHeader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useToast from 'hooks/useToast';
import { useCreateSubscriptionMutation } from 'services/api/core/billing/api';

import { SubscriptionForm } from '../../components/SubscriptionForm';

export const BillingSubscriptionCreate = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const [create] = useCreateSubscriptionMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await create(values);

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [create, toast, formatMessage, navigate]
  );

  return (
    <Fragment>
      <PageHeader>
        <BackLink>Створення підписки на тарифний план</BackLink>
      </PageHeader>
      <Card>
        <SubscriptionForm defaultValues={{}} onSubmit={handleSubmit} />
      </Card>
    </Fragment>
  );
};
