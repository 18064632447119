import { ReactNode } from 'react';
import { FileUploader, IFileUploaderProps } from '../FileUploader';

interface IWrappedProps {
  description?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  name: string;
  uploaderLabel?: ReactNode;
}

type UploaderFieldProps = IWrappedProps &
  Omit<IFileUploaderProps, 'name' | 'onChange'> & {
    onChange?: any;
  };

export const UploaderField = ({ multiple, onChange, onRemove, ...rest }: UploaderFieldProps) => (
  <FileUploader
    {...rest}
    multiple={multiple}
    onChange={(files) => {
      const [file] = files;

      return multiple ? onChange(files) : onChange(file);
    }}
    onRemove={(file) => {
      onChange(undefined);

      onRemove && onRemove(file);
    }}
  />
);
