import { FC } from 'react';
import cs from 'classnames';
import { Icon, IconName } from '@smart-kasa/ui';

import styles from './ReceiptsStatus.module.scss';

interface IReceiptsStatusIcon {
  icon?: IconName;
  color?: string;
}

export const ReceiptsStatusIcon: FC<IReceiptsStatusIcon> = ({ icon, color }) => (
  <div className={cs(styles.icon, color && styles[color])}>
    {icon && <Icon name={icon} size="small" />}
  </div>
);
