import { FormattedMessage } from 'react-intl';
import { Button, Card, CardTitle, Preloader } from '@smart-kasa/ui';

import { AreaChart, ImageIcon } from 'components';

import Sentry from 'assets/services/sentry.svg';
import { useGetOfflineStatsQuery } from 'services/api/core/stats/api';

export const OfflineEventsChart = () => {
  const { data: events, isLoading } = useGetOfflineStatsQuery();

  const openSentry = () => {
    window.open('https://smartkasa.sentry.io/discover/results', '_blank', 'noreferrer');
  };

  return (
    <Card>
      <CardTitle
        actions={
          <Button
            color="dark"
            size="small"
            rightIcon={<ImageIcon src={Sentry} alt="Sentry" size={12} />}
            onClick={openSentry}
          >
            Sentry events
          </Button>
        }
      >
        <FormattedMessage id="dashboard.connection" />
      </CardTitle>

      {isLoading ? (
        <Preloader />
      ) : (
        <AreaChart data={events?.data} height={150} xAxis="date" yAxis="value" />
      )}
    </Card>
  );
};
