import { FC, createContext } from 'react';

export interface IConfig {
  apiBaseURL: string;
  locale: string;
}

export interface IConfigContext {
  config: IConfig;
}

export const ConfigContext = createContext<IConfigContext>({
  config: {
    apiBaseURL: '',
    locale: 'uk',
  },
});

export const ConfigContextProvider: FC<{ config: IConfig }> = ({ config, children }) => {
  return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>;
};
