import { HTMLAttributes, ReactNode } from 'react';
import cs from 'classnames';

import styles from './Caption.module.scss';

export interface CaptionProps extends HTMLAttributes<HTMLDivElement> {
  name?: ReactNode;
}

export const Caption = ({ name, className, children, ...rest }: CaptionProps) => (
  <div {...rest} className={cs(styles.row, className)}>
    <div className={styles.heading}>{name}</div>
    {children}
  </div>
);
