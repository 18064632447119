import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export type BarChartProps = {
  width?: string | number;
  height?: string | number;
  xAxis?: string;
  yAxis?: string;
  color?: string;
  data?: any[];
};

export const BarChart = ({
  width,
  height = 250,
  xAxis = 'name',
  yAxis = 'value',
  color = '#57b7da',
  data = [],
  ...rest
}: BarChartProps) => (
  <ResponsiveContainer width={width} height={height}>
    <Chart data={data} {...rest}>
      <CartesianGrid stroke="#f9f7fa" vertical={false} />
      <XAxis dataKey={xAxis} tick={{ fontSize: 12 }} stroke="#827392" />
      <YAxis tick={{ fontSize: 12 }} width={40} stroke="#827392" />
      <Tooltip
        wrapperStyle={{ outline: 'none' }}
        contentStyle={{
          fontSize: 12,
          border: '1px solid rgb(219, 214, 225)',
          borderRadius: 6,
          boxShadow: '-3px 4px 8px -8px rgba(34, 60, 80, .2)',
        }}
      />
      <Bar dataKey={yAxis} fill={color} />
    </Chart>
  </ResponsiveContainer>
);
