import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Icon, Page, Preloader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useConfirm from 'hooks/useConfirm';
import useToast from 'hooks/useToast';

import {
  useDeleteTemplateMutation,
  useFindTemplateQuery,
  useUpdateTemplateMutation,
} from 'services/api/core/template/api';
import { TemplateForm } from 'features/templates/components/TemplateForm';

export const TemplateEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const { data: template, isLoading } = useFindTemplateQuery(Number(id));
  const [update] = useUpdateTemplateMutation();
  const [remove] = useDeleteTemplateMutation();
  const { confirm } = useConfirm();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await update({ id, ...values });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [id, update, toast, formatMessage, navigate]
  );

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

    if (!isConfirmed) return;

    remove(Number(id)).then(() => {
      navigate(-1);
    });
  }, [id, confirm, remove, navigate, formatMessage]);

  if (isLoading) return <Preloader />;

  return (
    <Page
      title={<BackLink>Редагування шаблону</BackLink>}
      actions={
        <Button
          color="danger"
          leftIcon={<Icon name="trash" size="medium" />}
          onClick={handleDelete}
        >
          Видалити
        </Button>
      }
    >
      <TemplateForm defaultValues={template?.data} onSubmit={handleSubmit} />
    </Page>
  );
};
