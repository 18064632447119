import { Pagination, CoreResponse } from 'types/api';

import { BillingInvoiceFilterParams, IBillingInvoice } from './types';
import { billingApi } from '../billing/api';

const invoiceApi = billingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBillingInvoices: builder.query<
      CoreResponse<IBillingInvoice[]>,
      Pagination<IBillingInvoice> & BillingInvoiceFilterParams
    >({
      query: (params?) => ({ url: 'core/manage/billing/invoices', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetBillingInvoicesQuery } = invoiceApi;
