import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, EmptyState } from '@smart-kasa/ui';

import Help from 'assets/help.png';
import styles from './NoMatch.module.scss';

export const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={Help} alt="Help" className={styles.image} />

        <EmptyState
          header={<FormattedMessage id="not_found.title" />}
          description={<FormattedMessage id="not_found.description" />}
        />

        <Button color="dark" onClick={() => navigate('/')}>
          <FormattedMessage id="not_found.link" />
        </Button>
      </div>
    </div>
  );
};
