import { Tag } from '@smart-kasa/ui';

import { ShiftState } from 'services/api/core/shifts/enums';

const STATE_MAPPING: Record<ShiftState, { color: any; name: string }> = {
  [ShiftState.active]: { color: 'orange', name: 'Активна' },
  [ShiftState.closed]: { color: 'grey', name: 'Закрита' },
  [ShiftState.expired]: { color: 'cyan', name: 'Прострочена' },
};

type ShiftStatusProps = {
  state: ShiftState;
};

export const ShiftStatus = ({ state }: ShiftStatusProps) => (
  <Tag color={STATE_MAPPING[state].color}>{STATE_MAPPING[state].name}</Tag>
);
