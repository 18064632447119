import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { Datepicker, DatepickerProps } from 'components';

import { WithFormProps } from './withForm';

export type DatepickerFieldProps = DatepickerProps & WithFormProps & ControllerRenderProps;

export const DatepickerField: React.FC<DatepickerFieldProps> = React.forwardRef<
  HTMLInputElement,
  DatepickerFieldProps
>(({ name, value, ...rest }, _ref) => <Datepicker selected={value} {...rest} />);
