export const LOCALES = ['uk', 'ru', 'en'];
export const LOCALE_OPTIONS = [
  {
    value: 'uk',
    label: 'Українська',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
  {
    value: 'en',
    label: 'English',
  },
];
