import { Tag } from '@smart-kasa/ui';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';

const STATE_NAME: Record<BillingSubscriptionState, { name: string; color: string }> = {
  [BillingSubscriptionState.WAITING]: {
    name: 'Очікує активації',
    color: 'grey',
  },
  [BillingSubscriptionState.ACTIVE]: {
    name: 'Активний',
    color: 'blue',
  },
  [BillingSubscriptionState.CANCELED]: {
    name: 'Видалений',
    color: 'orange',
  },
};

export type SubscriptionStatusProps = {
  state: BillingSubscriptionState;
  children?: never;
};

export const SubscriptionStatus = ({ state }) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
