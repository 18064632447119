import { useNavigate } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Button, ButtonIcon, DataTable, Hint, IDataTableColumn, Icon, Page } from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';

import { useGetPlatformsQuery } from 'services/api/core/platform/api';
import { IAuthPlatform } from 'services/api/core/platform/types';
import { PlatformStatus } from 'features/platforms/components/PlatformStatus';
import { PlatformType } from 'features/platforms/components/PlatformType';

export const PlatformList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onSortChange } = usePagination();
  const { data: platforms, isLoading } = useGetPlatformsQuery(query);

  const columns: IDataTableColumn<IAuthPlatform>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'platform.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'platform.name' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'platform.state' }),
      render: (state) => <PlatformStatus state={state} />,
    },
    {
      key: 'platformTypeId',
      title: formatMessage({ id: 'platform.type' }),
      render: (platformTypeId) => <PlatformType type={platformTypeId} />,
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'platform.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
    {
      key: 'id',
      render: () => (
        <ButtonIcon>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page
      title="API-ключі"
      actions={
        <Button
          color="dark"
          rightIcon={<Icon name="plus" size="small" />}
          onClick={() => navigate('new')}
        >
          Створити
        </Button>
      }
    >
      <DataTable
        rowIdKey="id"
        data={platforms?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`)}
        onSortChange={onSortChange}
      />
    </Page>
  );
};
