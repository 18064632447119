import { useContext } from 'react';
import { PageHeader } from '@smart-kasa/ui';

import { SessionContext } from 'contexts/SessionContext';
import { LoginForm } from './components/LoginForm';

export const Auth = () => {
  const { login } = useContext(SessionContext);

  return (
    <>
      <PageHeader>Вхід</PageHeader>

      <LoginForm defaultValues={{}} onSubmit={login} />
    </>
  );
};
