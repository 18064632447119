import { useContext } from 'react';
import { AvatarItem, DropDown, Icon } from '@smart-kasa/ui';

import { SessionContext } from 'contexts/SessionContext';
import styles from './Account.module.scss';
import { AdminContext } from 'contexts/AdminContext';

export const Account = () => {
  const { logout } = useContext(SessionContext);
  const { profile } = useContext(AdminContext);

  return (
    <DropDown type="arrow" className={styles.profile}>
      <DropDown.Toggle>
        <AvatarItem
          avatar={<Icon name="support" className={styles.icon} />}
          primaryText={profile?.name}
          secondaryText={profile?.email}
        />
      </DropDown.Toggle>
      <DropDown.Menu className={styles.menu}>
        <DropDown.Item icon="logout" onClick={logout}>
          Вийти з системи
        </DropDown.Item>
      </DropDown.Menu>
    </DropDown>
  );
};
