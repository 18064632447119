import { useNavigate } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Button,
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Page,
  Pagination,
} from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';
import { useGetTemplatesQuery } from 'services/api/core/template/api';
import { ITemplateMessage } from 'services/api/core/template/types';
import { TemplateMessageType } from 'services/api/core/template/enums';

export const TemplateList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange, onSortChange } = usePagination();
  const { data: templates, isLoading } = useGetTemplatesQuery(query);

  const columns: IDataTableColumn<ITemplateMessage>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'template.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'templateTypeId',
      title: formatMessage({ id: 'template.type' }),
      render: (templateTypeId) =>
        formatMessage({ id: `template.type.${TemplateMessageType[templateTypeId]}` }),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'template.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: formatMessage({ id: 'template.updatedAt' }),
      render: (updatedAt) => <FormattedDate value={updatedAt} />,
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon onClick={() => navigate(`${id}`)}>
          <Icon name="edit" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page
      title="Шаблони повідомлень"
      actions={
        <Button
          color="dark"
          rightIcon={<Icon name="plus" size="small" />}
          onClick={() => navigate('new')}
        >
          Створити
        </Button>
      }
    >
      <DataTable
        rowIdKey="id"
        data={templates?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`)}
        onSortChange={onSortChange}
      />

      <Pagination
        totalItems={templates?.meta?.totalCount}
        currentPage={templates?.meta?.currentPage}
        perPage={templates?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Page>
  );
};
