import { Tag } from '@smart-kasa/ui';

import { CoreTerminalState } from 'services/api/core/pos/enums';

const STATE_NAME: Record<CoreTerminalState, { name: string; color: string }> = {
  [CoreTerminalState.PENDING]: {
    name: 'На складі',
    color: 'grey',
  },
  [CoreTerminalState.ACTIVE]: {
    name: "Підв'язаний",
    color: 'green',
  },
  [CoreTerminalState.DELETED]: {
    name: 'Деактивований',
    color: 'orange',
  },
};

export type CoreTerminalStatusProps = {
  state: CoreTerminalState;
  children?: never;
};

export const CoreTerminalStatus = ({ state }) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
