import { useIntl } from 'react-intl';
import { Button } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { TerminalState } from 'services/api/core/pos/enums';

export interface ITerminalFilterForm {
  defaultValues: any;
  onSubmit: (values) => void;
}

const STATUSES = [
  {
    value: TerminalState.PENDING,
    label: 'Очікує активації',
  },
  {
    value: TerminalState.ACTIVE,
    label: 'Активний',
  },
  {
    value: TerminalState.DELETED,
    label: 'Видалений',
  },
];

export const TerminalFilterForm: React.FC<ITerminalFilterForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any> onSubmit={onSubmit} form={{ defaultValues }}>
      <Field.Input name="serialNumber" label={formatMessage({ id: 'pos.terminal.serial' })} />
      <Field.Select
        name="state"
        placeholder="Оберіть статус"
        label={formatMessage({ id: 'pos.terminal.state' })}
        options={STATUSES}
      />

      <Button type="submit" color="dark" width="full">
        {formatMessage({ id: 'buttons.filter' })}
      </Button>
    </Form>
  );
};
