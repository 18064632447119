import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

export function useQuery<T = any>(): {
  query: T;
  push: (query: Partial<T>) => void;
} {
  const { search } = useLocation();
  const navigate = useNavigate();

  const setQuery = useCallback(
    (query: object) => {
      navigate('?' + qs.stringify(query, { arrayFormat: 'bracket' }));
    },
    [navigate]
  );

  const query = useMemo(
    () => (search ? qs.parse(search, { arrayFormat: 'bracket' }) : {}),
    [search]
  );

  return {
    query: query as any as T,
    push: setQuery,
  };
}
