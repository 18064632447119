import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TabsNavigation, TabsNavigationItem } from '@smart-kasa/ui';

import { IAMUser } from 'services/api/core/user/types';
import styles from './Navigation.module.scss';

export type UserNavigationProps = {
  user: IAMUser;
};

const TabNavigationLink = ({ to, ...rest }) => (
  <NavLink to={to} className={styles.link} end>
    {({ isActive }) => <TabsNavigationItem isActive={isActive} {...rest} />}
  </NavLink>
);

export const Navigation: React.FC<UserNavigationProps> = ({ user }) => (
  <TabsNavigation>
    <TabNavigationLink to="">
      <FormattedMessage id="user.navigation.show" />
    </TabNavigationLink>
    <TabNavigationLink to="billing/balance">
      <FormattedMessage id="user.navigation.billing-balance" />
    </TabNavigationLink>
    <TabNavigationLink to="billing/subscriptions">
      <FormattedMessage id="user.navigation.billing-subscriptions" />
    </TabNavigationLink>
    <TabNavigationLink to="billing/invoices">
      <FormattedMessage id="user.navigation.billing-invoices" />
    </TabNavigationLink>
    <TabNavigationLink to="terminals">
      <FormattedMessage id="user.navigation.terminals" />
    </TabNavigationLink>
    <TabNavigationLink to="shops">
      <FormattedMessage id="user.navigation.shops" />
    </TabNavigationLink>
    <TabNavigationLink to="employees">
      <FormattedMessage id="user.navigation.employees" />
    </TabNavigationLink>
    <TabNavigationLink to="shifts">
      <FormattedMessage id="user.navigation.shifts" />
    </TabNavigationLink>
  </TabsNavigation>
);
