import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { PlatformFormSchema } from './PlatformFormSchema';
import { AuthPlatformType } from 'services/api/core/platform/enums';

interface IPlatformForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

const TYPES = [
  {
    value: AuthPlatformType.admin,
    label: 'Адміністративна панель',
  },
  {
    value: AuthPlatformType.web,
    label: 'Кабінет',
  },
  {
    value: AuthPlatformType.mobile,
    label: 'Мобільний застосунок',
  },
  {
    value: AuthPlatformType.terminal,
    label: 'POS',
  },
];

export const PlatformForm: FC<IPlatformForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(PlatformFormSchema),
      }}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Field.Input name="name" label={formatMessage({ id: 'platform.name' })} />
        </Col>
        <Col span={12}>
          <Field.Select
            name="platformTypeId"
            label={formatMessage({ id: 'platform.type' })}
            placeholder="Оберіть платформу"
            options={TYPES}
          />
        </Col>
      </Row>

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
