import { createSlice } from '@reduxjs/toolkit';

import { authApi } from 'services/api/auth/api';
import { IAuthState } from './types';

const initialState: IAuthState = {
  accessToken: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut(state) {
      state.accessToken = initialState.accessToken;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
      state.accessToken = action.payload.token;
    });
  },
});

export const { signOut } = authSlice.actions;
