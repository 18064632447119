import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Hint, Strong } from '@smart-kasa/ui';

import type { IBillingPaymentTransaction } from 'services/api/core/invoices/types';
import { BillingProviderType } from 'services/api/core/invoices/enums';

export type BillingTransactionProps = {
  transaction: IBillingPaymentTransaction;
  children?: never;
};

export const BillingTransaction = ({ transaction }: BillingTransactionProps) => {
  if (!transaction) return <Fragment>-</Fragment>;

  return (
    <Fragment>
      <Strong>
        <FormattedMessage
          id={`billing.provider.${BillingProviderType[transaction.providerTypeId]}`}
        />
      </Strong>
      <Hint margin={0}>{transaction.payload.clientName}</Hint>
      <Hint margin={0}>
        {transaction.payload.cardType} {transaction.payload.cardPan}
      </Hint>
      <Hint margin={0}>{transaction.payload.issuerBankName}</Hint>
    </Fragment>
  );
};
