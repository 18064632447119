import { ReactNode, memo } from 'react';
import { Drawer } from '@smart-kasa/ui';

import { TerminalFilterForm, ITerminalFilterForm } from './TerminalFilterForm';

export type TerminalFilterProps = ITerminalFilterForm & {
  title?: ReactNode;
  show: boolean;
  onClose: () => void;
};

export const TerminalFilter: React.FC<TerminalFilterProps> = memo(
  ({ title, show, onSubmit, onClose, ...rest }) => {
    const handleSubmit = (data) => {
      onClose();
      onSubmit(data);
    };

    return (
      <Drawer title={title} show={show} onClose={onClose} footer={null}>
        <TerminalFilterForm onSubmit={handleSubmit} {...rest} />
      </Drawer>
    );
  }
);
