import { FormattedDate, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Strong, Tag } from '@smart-kasa/ui';

import { Caption } from 'components';

import { IShift } from 'services/api/core/shifts/types';
import { ShiftStatus } from '../ShiftStatus';

type ShiftInfoProps = {
  shift: IShift;
  children?: never;
};

export const ShiftInfo = ({ shift }: ShiftInfoProps) => (
  <Card>
    <Row gutter={10}>
      <Col span={7}>
        <Caption name={<FormattedMessage id="pos.shift.type" />}>
          {shift.fiscalNumber ? (
            <Tag color="violet">
              <FormattedMessage id="pos.shift.type.fiscal" />
            </Tag>
          ) : (
            <Tag color="green">
              <FormattedMessage id="pos.shift.type.non-fiscal" />
            </Tag>
          )}
        </Caption>
        <Caption name={<FormattedMessage id="pos.shift.id" />}>{shift.id}</Caption>
      </Col>

      <Col span={5}>
        <Caption name={<FormattedMessage id="pos.shift.state" />}>
          <ShiftStatus state={shift.state} />
        </Caption>
        <Caption name={<FormattedMessage id="pos.shift.device" />}>{shift.deviceUid}</Caption>
      </Col>

      <Col span={7}>
        <Caption name={<FormattedMessage id="pos.shift.opened" />}>
          <FormattedDate value={shift.openedAt} dateStyle="short" timeStyle="short" />{' '}
          <Strong>{shift.openedBy}</Strong>
        </Caption>

        {shift.closedAt && (
          <Caption name={<FormattedMessage id="pos.shift.closed" />}>
            <FormattedDate value={shift.closedAt} dateStyle="short" timeStyle="short" />{' '}
            <Strong>{shift.closedBy}</Strong>
          </Caption>
        )}
      </Col>

      <Col span={5}>
        {shift.registrarFiscalNumber && (
          <Caption name={<FormattedMessage id="pos.shift.registrar-fiscal-number" />}>
            {shift.registrarFiscalNumber}
          </Caption>
        )}

        {shift.fiscalNumber && (
          <Caption name={<FormattedMessage id="pos.shift.fiscal-number" />}>
            {shift.fiscalNumber}
          </Caption>
        )}
      </Col>
    </Row>
  </Card>
);
