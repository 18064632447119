import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { RootState } from 'redux/store';

const { REACT_APP_API_BASE_URL } = process.env;

export const authBaseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) headers.set('Authorization', token);

    return headers;
  },
});
