import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { CoreTerminalFormSchema } from './CoreTerminalFormSchema';

interface ICoreTerminalForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const CoreTerminalForm: FC<ICoreTerminalForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(CoreTerminalFormSchema),
      }}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Field.Input name="title" label={formatMessage({ id: 'pos.terminal.title' })} />
        </Col>
        <Col span={12}>
          <Field.Input name="serialNumber" label={formatMessage({ id: 'pos.terminal.serial' })} />
        </Col>
      </Row>

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
