import { useNavigate, useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Button, ButtonIcon, Col, Icon, InlineSpace, Page, Preloader, Row } from '@smart-kasa/ui';

import { BackLink, Caption } from 'components';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import { useFindPlatformQuery } from 'services/api/core/platform/api';
import { PlatformStatus } from 'features/platforms/components/PlatformStatus';
import { PlatformType } from 'features/platforms/components/PlatformType';

export const PlatformShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { data: record } = useFindPlatformQuery(Number(id));
  const [copy] = useCopyToClipboard();
  const platform = record?.data;

  if (!platform) return <Preloader />;

  return (
    <Page
      title={<BackLink>{platform.name}</BackLink>}
      actions={
        <Button
          color="dark"
          leftIcon={<Icon name="edit" size="medium" />}
          onClick={() => navigate('edit')}
        >
          Редагувати
        </Button>
      }
    >
      <Row gutter={10}>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'platform.type' })}>
            <PlatformType type={platform.platformTypeId} />
          </Caption>
          <Caption name={formatMessage({ id: 'platform.api-key' })}>
            {platform.apiKey}
            <InlineSpace />
            <ButtonIcon size="small" onClick={() => copy(platform.apiKey)}>
              <Icon name="copy" size="medium" />
            </ButtonIcon>
          </Caption>
        </Col>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'platform.state' })}>
            <PlatformStatus state={platform.state} />
          </Caption>
          <Caption name={formatMessage({ id: 'platform.createdAt' })}>
            <FormattedDate value={platform.createdAt} />
          </Caption>
        </Col>
      </Row>
    </Page>
  );
};
