import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Icon, Page, Preloader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useConfirm from 'hooks/useConfirm';
import useToast from 'hooks/useToast';
import {
  useDeletePlatformMutation,
  useFindPlatformQuery,
  useUpdatePlatformMutation,
} from 'services/api/core/platform/api';

import { PlatformForm } from '../../components/PlatformForm';

export const PlatformEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const { data: unit, isLoading } = useFindPlatformQuery(Number(id));
  const [update] = useUpdatePlatformMutation();
  const [remove] = useDeletePlatformMutation();
  const { confirm } = useConfirm();

  const handleSubmit = useCallback(
    async ({ preferences, ...values }) => {
      const result = await update({ id: Number(id), ...values });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [id, update, toast, formatMessage, navigate]
  );

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

    if (!isConfirmed) return;

    remove(Number(id)).then(() => {
      navigate('/api-keys');
    });
  }, [id, confirm, remove, navigate, formatMessage]);

  if (isLoading) return <Preloader />;

  return (
    <Page
      title={<BackLink>Редагування ключа</BackLink>}
      actions={
        <Button
          color="danger"
          leftIcon={<Icon name="trash" size="medium" />}
          onClick={handleDelete}
        >
          Видалити
        </Button>
      }
    >
      <PlatformForm defaultValues={unit?.data} onSubmit={handleSubmit} />
    </Page>
  );
};
