import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Card, Icon, PageHeader, Preloader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useConfirm from 'hooks/useConfirm';
import useToast from 'hooks/useToast';
import {
  useDeleteSubscriptionMutation,
  useFindSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from 'services/api/core/billing/api';

import { SubscriptionForm } from '../../components/SubscriptionForm';

export const BillingSubscriptionEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const { confirm } = useConfirm();
  const { data: subscription, isLoading } = useFindSubscriptionQuery(Number(id));
  const [update] = useUpdateSubscriptionMutation();
  const [remove] = useDeleteSubscriptionMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await update({ id, ...values });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [id, update, toast, formatMessage, navigate]
  );

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

    if (isConfirmed) remove(Number(id)).then(() => navigate(-1));
  }, [id, confirm, remove, navigate, formatMessage]);

  if (isLoading) return <Preloader />;

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button
            color="danger"
            leftIcon={<Icon name="trash" size="medium" />}
            onClick={handleDelete}
          >
            Видалити
          </Button>
        }
      >
        <BackLink>Редагування підписки на тарифний план</BackLink>
      </PageHeader>
      <Card>
        <SubscriptionForm defaultValues={subscription?.data} onSubmit={handleSubmit} />
      </Card>
    </Fragment>
  );
};
