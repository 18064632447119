import { Routes, Route } from 'react-router-dom';

import { TemplateList } from './routes/TemplateList';
import { TemplateCreate } from './routes/TemplateCreate';
import { TemplateEdit } from './routes/TemplateEdit';

export const Templates = () => (
  <Routes>
    <Route index element={<TemplateList />} />
    <Route path="/new" element={<TemplateCreate />} />
    <Route path="/:id" element={<TemplateEdit />} />
  </Routes>
);
