import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export type RichTextProps = {
  value: string;
  ref?: any;
  height?: number;
  onChange?: (event, editor) => void;
};

export const RichText: React.FC<RichTextProps> = React.forwardRef<Editor, RichTextProps>(
  ({ value, onChange, height = 500, ...rest }, ref) => (
    <Editor
      {...rest}
      ref={ref}
      apiKey={process.env.REACT_APP_TINY_API_KEY}
      initialValue={value}
      onEditorChange={onChange}
      init={{
        height,
        menubar: false,
        plugins: 'code',
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | code',
      }}
    />
  )
);
