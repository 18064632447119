import { Routes, Route } from 'react-router-dom';

import { FeedbackList } from './routes/FeedbackList';
import { FeedbackShow } from './routes/FeedbackShow';

export const Feedbacks = () => (
  <Routes>
    <Route index element={<FeedbackList />} />
    <Route path="/:id" element={<FeedbackShow />} />
  </Routes>
);
