import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Pagination,
  Preloader,
  PageHeader,
  Card,
  EmptyState,
  InlineSpace,
} from '@smart-kasa/ui';

import { BackLink } from 'components';
import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';

import { useGetReceiptsQuery } from 'services/api/core/receipts/api';
import { useFindShiftQuery } from 'services/api/core/shifts/api';
import { ReceiptsTable } from 'features/receipts/components/ReceiptsTable';
import { ShiftInfo } from 'features/shifts/components/ShiftInfo';
import { Report } from 'features/shifts/components/Report';
import { useGetReportsQuery } from 'services/api/core/reports/api';

export const ShiftShow = () => {
  const { id } = useParams();
  const { query } = useQuery();
  const { onPageChange } = usePagination();
  const { data: shift } = useFindShiftQuery(id as string);
  const { data: reports } = useGetReportsQuery({ shiftId: id });
  const { data: receipts, isLoading } = useGetReceiptsQuery({ ...query, shiftId: id });

  if (!shift) return <Preloader />;

  return (
    <Fragment>
      <PageHeader>
        <BackLink>
          <FormattedMessage
            id="pos.shift.heading"
            values={{
              date: (
                <FormattedDate value={shift?.data.openedAt} dateStyle="short" timeStyle="short" />
              ),
            }}
          />
        </BackLink>
      </PageHeader>

      <ShiftInfo shift={shift.data} />
      <InlineSpace direction="vertical" size={20} />

      <Row gutter={10}>
        <Col span={16}>
          <Card>
            <ReceiptsTable data={receipts?.data} isLoading={isLoading} />
            <Pagination
              totalItems={receipts?.meta?.totalCount}
              currentPage={receipts?.meta?.currentPage}
              perPage={receipts?.meta?.limitValue}
              onPageChange={onPageChange}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            {reports && reports.data.length ? (
              <Report report={reports.data[0]} />
            ) : (
              <EmptyState description="Звіт недоступний" />
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
