import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Page } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useToast from 'hooks/useToast';

import { useCreateTemplateMutation } from 'services/api/core/template/api';
import { TemplateForm } from 'features/templates/components/TemplateForm';

export const TemplateCreate = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const [create] = useCreateTemplateMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await create(values);

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [create, toast, formatMessage, navigate]
  );

  return (
    <Page title={<BackLink>Додання шаблону</BackLink>}>
      <TemplateForm defaultValues={{}} onSubmit={handleSubmit} />
    </Page>
  );
};
