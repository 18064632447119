import { Routes, Route } from 'react-router-dom';

import BillingPlans from './routes/BillingPlans/BillingPlans';
import BillingSubscriptions from './routes/BillingSubscriptions/BillingSubscriptions';

export const Billing = () => (
  <Routes>
    <Route path="plans/*" element={<BillingPlans />} />
    <Route path="subscriptions/*" element={<BillingSubscriptions />} />
  </Routes>
);
