import { Routes, Route } from 'react-router-dom';

import { BillingSubscriptionList } from './routes/BillingSubscriptionList';
import { BillingSubscriptionCreate } from './routes/BillingSubscriptionCreate';
import { BillingSubscriptionEdit } from './routes/BillingSubscriptionEdit';

const BillingSubscriptions = () => (
  <Routes>
    <Route index element={<BillingSubscriptionList />} />
    <Route path="/new" element={<BillingSubscriptionCreate />} />
    <Route path="/:id" element={<BillingSubscriptionEdit />} />
  </Routes>
);

export default BillingSubscriptions;
