import { Outlet, useNavigate } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Button,
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Inline,
  Page,
  Pagination,
} from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import useModal from 'hooks/useModal';
import { usePagination } from 'hooks/usePagination';

import { useGetTerminalsQuery } from 'services/api/core/pos/api';
import { ITerminal } from 'services/api/core/pos/types';
import { TerminalStatus } from '../../components/TerminalStatus';
import { TerminalFilter } from '../../components/TerminalFilter';

export const TerminalList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query, push } = useQuery();
  const { isShowing, showModal, hideModal } = useModal();
  const { onPageChange, onSortChange } = usePagination();
  const { data: terminals, isLoading } = useGetTerminalsQuery(query);

  const columns: IDataTableColumn<ITerminal>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'pos.terminal.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'serialNumber',
      title: formatMessage({ id: 'pos.terminal.serial' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'pos.terminal.state' }),
      render: (state) => <TerminalStatus state={state} />,
    },
    {
      key: 'activatedAt',
      title: formatMessage({ id: 'pos.terminal.activatedAt' }),
      render: (activatedAt) => (activatedAt ? <FormattedDate value={activatedAt} /> : '-'),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'pos.terminal.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon onClick={() => navigate(`${id}`)}>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page
      title="Активовані термінали"
      actions={
        <Inline>
          <Button
            color="sub-primary"
            rightIcon={<Icon name="filter" size="medium" />}
            onClick={showModal}
          >
            Фільтрація
          </Button>
        </Inline>
      }
    >
      <DataTable
        rowIdKey="id"
        data={terminals?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`)}
        onSortChange={onSortChange}
      />
      <Pagination
        totalItems={terminals?.meta?.totalCount}
        currentPage={terminals?.meta?.currentPage}
        perPage={terminals?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <TerminalFilter
        title="Фільтрація"
        show={isShowing}
        defaultValues={query}
        onSubmit={(values) => push(values)}
        onClose={hideModal}
      />

      <Outlet />
    </Page>
  );
};
