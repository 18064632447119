import { Fragment } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import {
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Paragraph,
  Strong,
} from '@smart-kasa/ui';

import { IPaymentTransaction, IReceipt } from 'services/api/core/receipts/types';
import { ReceiptsStatus } from '../ReceiptsStatus';
import { ReceiptPaymentMethod } from '../ReceiptPaymentMethod';

export const ReceiptsTable = ({ data, ...rest }) => {
  const openReceipt = (receipt: IReceipt) =>
    window.open(`https://receipt.smartkasa.ua/${receipt.id}`, '_blank', 'noreferrer');

  const columns: IDataTableColumn<IReceipt>[] = [
    {
      key: 'type',
      title: 'Статус',
      render: (type, { state, title, description }) => (
        <Fragment>
          <ReceiptsStatus title={title} type={type} state={state} />
          <Paragraph>{description && <Hint>{description}</Hint>}</Paragraph>
        </Fragment>
      ),
    },
    {
      key: 'fiscalNumber',
      title: 'ФН чеку',
      render: (fiscalNumber) => fiscalNumber || '-',
    },
    {
      key: 'totalAmount',
      title: 'Сума',
      render: (totalAmount) => (
        <FormattedNumber value={totalAmount} style="currency" currency="UAH" />
      ),
    },
    {
      key: 'paymentTransactions',
      title: 'Оплата',
      render: (paymentTransactions?: IPaymentTransaction[]) =>
        paymentTransactions?.map((transaction) => (
          <ReceiptPaymentMethod
            key={transaction.id}
            type={transaction.transactionTypeId}
            name={transaction.pan}
          />
        )),
    },
    {
      key: 'createdAt',
      title: 'Дата',
      render: (createdAt) => (
        <Hint>
          <Strong>
            <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
          </Strong>
        </Hint>
      ),
    },
    {
      key: 'id',
      render: () => (
        <ButtonIcon>
          <Icon name="receipt" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <DataTable
      rowIdKey="id"
      data={data}
      columns={columns}
      emptyText="Чеки відсутні чи недоступні"
      onRowClick={openReceipt}
      {...rest}
    />
  );
};
