import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardTitle, Col, Hint, Preloader, Row, Strong, UAH } from '@smart-kasa/ui';

import { useFindWalletQuery, useUpdateWalletMutation } from 'services/api/core/wallet/api';

import { ChargeForm } from '../../components/ChargeForm';
import styles from './Balance.module.scss';

export const Balance = () => {
  const { id } = useParams();
  const { data: wallet, refetch, isFetching } = useFindWalletQuery(Number(id));
  const [charge] = useUpdateWalletMutation();

  const handleSubmit = useCallback(
    async (values) => {
      await charge({ id, ...values });

      refetch();
    },
    [id, refetch, charge]
  );

  if (!wallet || isFetching) return <Preloader />;

  return (
    <Card>
      <Row>
        <Col span={12}>
          <CardTitle>Поточний баланс</CardTitle>
          <Hint>Для підключення тарифних планів та користування додатковими послугами</Hint>
          <Strong className={styles.balance}>
            <UAH value={wallet?.data.total} decreaseHundredths />
          </Strong>
        </Col>

        <Col span={12}>
          <CardTitle>Поповнення</CardTitle>
          <ChargeForm defaultValues={{}} onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Card>
  );
};
