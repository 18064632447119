import { FieldError } from 'react-hook-form';
import { ValidationError, SchemaOf } from 'yup';

const parseErrorSchema = (error: ValidationError) => {
  return (error.inner || []).reduce<Record<string, FieldError>>(
    (previous, error) => {
      if (!previous[error.path!]) {
        previous[error.path!] = { message: error.message, type: error.type! };
      }

      return previous;
    },
    {}
  );
};

const yupResolver = (validationSchema: SchemaOf<any>) => async (data: any) => {
  try {
    const values = await validationSchema.validate(data, {
      abortEarly: false,
    });

    return {
      values,
      errors: {},
    };
  } catch (e: any) {
    return {
      values: {},
      errors: parseErrorSchema(e),
    };
  }
};

export { parseErrorSchema, yupResolver };
