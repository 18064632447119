import { Tag } from '@smart-kasa/ui';

import { AuthPlatformType } from 'services/api/core/platform/enums';

const TYPE_NAME: Record<AuthPlatformType, { name: string; color: string }> = {
  [AuthPlatformType.admin]: {
    name: 'Адміністративна панель',
    color: 'cyan',
  },
  [AuthPlatformType.web]: {
    name: 'Кабінет',
    color: 'blue',
  },
  [AuthPlatformType.mobile]: {
    name: 'Мобільний застосунок',
    color: 'orange',
  },
  [AuthPlatformType.terminal]: {
    name: 'POS',
    color: 'yellow',
  },
};

export type PlatformTypeProps = {
  type: AuthPlatformType;
  children?: never;
};

export const PlatformType = ({ type }) => {
  const platform = TYPE_NAME[type];

  return <Tag color={platform.color}>{platform.name}</Tag>;
};
