import { Tag } from '@smart-kasa/ui';

import { AuthPlatformState } from 'services/api/core/platform/enums';

const STATE_NAME: Record<AuthPlatformState, { name: string; color: any }> = {
  [AuthPlatformState.paused]: {
    name: 'Призупинений',
    color: 'grey',
  },
  [AuthPlatformState.active]: {
    name: 'Активний',
    color: 'green',
  },
  [AuthPlatformState.deactivated]: {
    name: 'Деактивований',
    color: 'orange',
  },
};

export type PlatformStatusProps = {
  state: AuthPlatformState;
  children?: never;
};

export const PlatformStatus = ({ state }: PlatformStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
