import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { AsyncSelect, AsyncSelectProps } from '@smart-kasa/ui';

import { WithFormProps } from './withForm';

export type AutocompleteFieldProps = AsyncSelectProps & WithFormProps & ControllerRenderProps;

export const AutocompleteField: React.FC<AutocompleteFieldProps> = React.forwardRef<
  HTMLInputElement,
  AutocompleteFieldProps
>(({ getOptionValue, ...rest }, _ref) => <AsyncSelect {...rest} getOptionValue={getOptionValue} />);
