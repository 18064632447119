import { createApi } from '@reduxjs/toolkit/query/react';
import { coreBaseQuery } from 'services/queries/coreBaseQuery';
import type { CoreResponse } from 'types/api';

import type { IAdmin } from '../auth/types';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: coreBaseQuery,
  endpoints: (builder) => ({
    getProfile: builder.query<CoreResponse<IAdmin>, void>({
      query: () => ({
        url: 'auth/me',
      }),
    }),
  }),
});

export const { useGetProfileQuery } = adminApi;
