export enum ReceiptState {
  pending = 0,
  archived = 1,
  paid = 2,
  refunded = 3,
}

export enum ReceiptType {
  sale = 0,
  serviceInput = 1,
  serviceOutput = 2,
  refund = 3,
  reversal = 4,
  offlineStart = 102,
  offlineFinish = 103,
}

export enum DiscountType {
  percent = 0,
  currency = 1,
}

export enum PaymentNetwork {
  undefined = 0,
  mastercard = 5,
  visa = 4,
  americanExpress = 3,
  prostir = 9,
  unionPay = 6,
  cash = 100,
  other = 101,
}

export enum TransactionPaymentType {
  pay = 0,
  reversal = 1,
  refund = 2,
}

export enum TransactionType {
  cash = 0,
  cashless = 1,
  nonCash = 2,
}
