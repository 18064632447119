import { Tag } from '@smart-kasa/ui';

import { TerminalState } from 'services/api/core/pos/enums';

const STATE_NAME: Record<TerminalState, { name: string; color: string }> = {
  [TerminalState.PENDING]: {
    name: 'Очікує активації',
    color: 'grey',
  },
  [TerminalState.ACTIVE]: {
    name: 'Активний',
    color: 'green',
  },
  [TerminalState.DELETED]: {
    name: 'Видалений',
    color: 'orange',
  },
};

export type TerminalStatusProps = {
  state: TerminalState;
  children?: never;
};

export const TerminalStatus = ({ state }) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
