import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AvatarItem, Button } from '@smart-kasa/ui';

import { Field, Form, Picture } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { useGetShopsQuery } from 'services/api/core/shop/api';

import { TerminalFormSchema } from './TerminalFormSchema';

interface ITerminalForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const TerminalForm: FC<ITerminalForm> = ({ defaultValues, onSubmit }) => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { data: shops } = useGetShopsQuery({ ownerId: id, skipPagination: true });

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(TerminalFormSchema),
      }}
    >
      <Field.Input name="name" label={formatMessage({ id: 'pos.terminal.name' })} />

      <Field.Select
        name="shopId"
        label={formatMessage({ id: 'pos.terminal.shop' })}
        placeholder={formatMessage({ id: 'pos.terminal.shop' })}
        options={shops?.data}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        formatOptionLabel={(option) => (
          <AvatarItem
            avatar={<Picture src={option.picture} icon="bank" size="M" fill="grey" />}
            primaryText={option.title}
            secondaryText={option.address?.content}
          />
        )}
      />

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
