import { useNavigate, useParams } from 'react-router-dom';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import {
  ButtonIcon,
  Card,
  CardTitle,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Tag,
} from '@smart-kasa/ui';

import { SubscriptionStatus } from 'features/billing/routes/BillingSubscriptions/components/SubscriptionStatus';
import { IBillingSubscription } from 'services/api/core/billing/types';
import { useGetSubscriptionsQuery } from 'services/api/core/billing/api';

export const Subscriptions = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery({ ownerId: id });

  const columns: IDataTableColumn<IBillingSubscription>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'billing.subscription.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'plan',
      title: formatMessage({ id: 'billing.subscription.plan' }),
      render: (plan) => plan?.title,
    },
    {
      key: 'price',
      title: formatMessage({ id: 'billing.subscription.price' }),
      render: (price) => <FormattedNumber value={price} style="currency" currency="UAH" />,
    },
    {
      key: 'state',
      title: formatMessage({ id: 'billing.subscription.state' }),
      render: (state) => <SubscriptionStatus state={state} />,
    },
    {
      key: 'isPayPerTeminal',
      title: formatMessage({ id: 'billing.subscription.pay-per-terminal' }),
      render: (isPayPerTeminal) => <Tag color="grey">{isPayPerTeminal ? 'Так' : 'Ні'}</Tag>,
    },
    {
      key: 'activatedAt',
      title: formatMessage({ id: 'billing.subscription.activated-at' }),
      render: (activatedAt) => <FormattedDate value={activatedAt} />,
    },
    {
      key: 'activeUntil',
      title: formatMessage({ id: 'billing.subscription.active-until' }),
      render: (activeUntil) => (
        <FormattedDate value={activeUntil} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'billing.subscription.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Card>
      <CardTitle>Тарифні плани</CardTitle>

      <DataTable
        rowIdKey="id"
        data={subscriptions?.data}
        columns={columns}
        isLoading={isLoading}
        emptyText="Підписки та тарифні плани відсутні"
        onRowClick={({ id }) => navigate(`/billing/subscriptions/${id}`)}
      />
    </Card>
  );
};
