import { ConfirmDialog as Confirmation } from '@smart-kasa/ui';
import useConfirm from 'hooks/useConfirm';

export const ConfirmDialog = () => {
  const { onConfirm, onCancel, confirmState } = useConfirm();

  return (
    <Confirmation show={confirmState.show} onConfirm={onConfirm} onClose={onCancel}>
      {confirmState.text}
    </Confirmation>
  );
};
