import React, { ReactNode } from 'react';
import { RootState } from 'redux/store';

import { ILogin, IAdmin } from 'services/api/auth/types';
import { useSignInMutation } from 'services/api/auth/api';
import { useStoreDispatch, useStoreSelector } from 'hooks/useAppStore';
import { signOut } from 'features/auth/slice';

export interface ISessionContext {
  session?: string;
  isLogged: boolean;
  login: (data: ILogin) => Promise<IAdmin | any>;
  logout: () => void;
}

export const SessionContext = React.createContext<ISessionContext>({
  session: undefined,
  isLogged: false,
  login: () => Promise.resolve(),
  logout: () => void 0,
});

export const SessionContextProvider = ({ children }: { children: ReactNode }) => {
  const [signIn] = useSignInMutation();
  const dispatch = useStoreDispatch();
  const accessToken = useStoreSelector((state: RootState) => state.auth.accessToken);

  const logout = () => dispatch(signOut());

  return (
    <SessionContext.Provider
      value={{
        session: accessToken,
        isLogged: !!accessToken,
        login: signIn,
        logout: logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
