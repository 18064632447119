export const ROUTES = {
  root: () => `/`,

  // Auth
  auth: () => '/auth',

  // Dashboard
  dashboard: () => '/dashboard',

  // Inventory
  inventory: () => '/inventory/*',
  inventoryProducts: () => '/inventory/products',
  inventoryUnits: () => '/inventory/units',

  // Billing
  billing: () => '/billing/*',
  billingPlans: () => '/billing/plans',
  billingSubscriptions: () => '/billing/subscriptions',

  // POS
  pos: () => '/pos/*',
  posTerminals: () => '/pos/terminals',
  posCoreTerminals: () => '/pos/core-terminals',

  // IAM Users
  users: () => '/users',

  // Feedbacks
  feedbackMessages: () => '/messages',

  // Templates
  templates: () => '/templates',

  // FAQ
  faq: () => '/faq/*',
  faqCategories: () => '/faq/categories',
  faqQuestions: () => '/faq/questions',

  // Platforms
  platforms: () => '/api-keys',

  // Settings
  settings: () => '/settings',
};
