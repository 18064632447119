import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { IShop, ShopsFilterParams } from './types';

export const shopApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getShops: builder.query<CoreResponse<IShop[]>, Pagination<IShop> & ShopsFilterParams>({
      query: (params?) => ({
        url: 'core/manage/rsn/shops',
        params,
      }),
    }),
    findShop: builder.query<CoreResponse<IShop>, number>({
      query: (id) => ({
        url: `core/manage/rsn/shops/${id}`,
      }),
    }),
  }),
});

export const { useGetShopsQuery, useLazyGetShopsQuery, useFindShopQuery } = shopApi;
