import { HTMLAttributes } from 'react';
import cs from 'classnames';

import SmartKasa from 'assets/logo.svg';
import styles from './Logo.module.scss';

export interface ILogoProps extends HTMLAttributes<HTMLImageElement> {}

export const Logo: React.FC<ILogoProps> = ({ className, ...rest }) => (
  <div className={cs(styles.wrapper, className)}>
    <img src={SmartKasa} alt="Logo" {...rest} className={styles.logo} />
  </div>
);
