import { Routes, Route } from 'react-router-dom';

import { TerminalList } from './routes/TerminalList';
import { TerminalShow } from './routes/TerminalShow';

const Terminals = () => (
  <Routes>
    <Route path="" element={<TerminalList />} />
    <Route path="/:terminalId" element={<TerminalShow />} />
  </Routes>
);

export default Terminals;
