import { Routes, Route } from 'react-router-dom';

import { BillingPlanList } from './routes/BillingPlanList';
import { BillingPlanCreate } from './routes/BillingPlanCreate';
import { BillingPlanEdit } from './routes/BillingPlanEdit';

const BillingPlans = () => (
  <Routes>
    <Route index element={<BillingPlanList />} />
    <Route path="/new" element={<BillingPlanCreate />} />
    <Route path="/:planId" element={<BillingPlanEdit />} />
  </Routes>
);

export default BillingPlans;
