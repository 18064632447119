import { Fragment, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  CollapseContent,
  CollapseHeader,
  InlineSpace,
  Modal,
  PageHeader,
  Preloader,
  Row,
  Strong,
} from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import { BackLink, Caption } from 'components';
import {
  useDeleteTerminalMutation,
  useFindTerminalQuery,
  useUpdateTerminalMutation,
} from 'services/api/core/pos/api';

import { TerminalStatus } from '../../components/TerminalStatus';
import { TerminalForm } from '../../components/TerminalForm';
import styles from './TerminalShow.module.scss';

interface LocationModalState {
  modal?: boolean;
}

const TerminalWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationModalState;

  if (state?.modal)
    return (
      <Modal onClose={() => navigate('..')} className={styles.wrapper}>
        {children}
      </Modal>
    );

  return (
    <Fragment>
      <PageHeader>
        <BackLink>Керування терміналом</BackLink>
      </PageHeader>
      <Card className={styles.wrapper}>{children}</Card>
    </Fragment>
  );
};

export const TerminalShow = () => {
  const { terminalId } = useParams();
  const { formatMessage } = useIntl();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { data: record } = useFindTerminalQuery(Number(terminalId));
  const [update] = useUpdateTerminalMutation();
  const [remove] = useDeleteTerminalMutation();
  const terminal = record?.data;

  const handleUpdate = async (values) => {
    await update({ id: terminalId, ...values });
  };

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

    if (isConfirmed) {
      remove(Number(terminalId)).then(() => navigate(-1));
    }
  }, [terminalId, confirm, remove, navigate, formatMessage]);

  return (
    <TerminalWrapper>
      {!terminal ? (
        <Preloader />
      ) : (
        <Fragment>
          <CardTitle>Термінал #{terminal.id}</CardTitle>
          <Row gutter={10} className={styles.content}>
            <Col>
              <img src={terminal.picture?.url} alt="" className={styles.qr} />
            </Col>
            <Col>
              <TerminalStatus state={terminal.state} />
              <InlineSpace direction="vertical" />
              <Caption name="Серійний номер">{terminal.serialNumber}</Caption>
              <Caption name="Дата активації">
                {terminal.activatedAt ? <FormattedDate value={terminal.activatedAt} /> : '-'}
              </Caption>

              <Button size="small" color="danger" onClick={handleDelete}>
                Відв'язати
              </Button>
            </Col>
          </Row>

          <InlineSpace direction="vertical" />

          <Collapse theme="silver">
            <CollapseHeader>
              <Strong>Редагувати</Strong>
            </CollapseHeader>
            <CollapseContent>
              <TerminalForm defaultValues={terminal} onSubmit={handleUpdate} />
            </CollapseContent>
          </Collapse>
        </Fragment>
      )}
    </TerminalWrapper>
  );
};
