import { Routes, Route } from 'react-router-dom';

import CoreTerminals from './routes/CoreTerminals/CoreTerminals';
import Terminals from './routes/Terminals/Terminals';

export const POS = () => (
  <Routes>
    <Route path="core-terminals/*" element={<CoreTerminals />} />
    <Route path="terminals/*" element={<Terminals />} />
  </Routes>
);
