import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { IEmployee, EmployeesFilterParams } from './types';

export const employeeApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<
      CoreResponse<IEmployee[]>,
      Pagination<IEmployee> & EmployeesFilterParams
    >({
      query: (params?) => ({
        url: 'core/manage/rsn/employees',
        params,
      }),
    }),
  }),
});

export const { useGetEmployeesQuery } = employeeApi;
