import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Checkbox, ICheckboxProps } from '@smart-kasa/ui';

import { WithFormProps } from './withForm';

export type CheckboxFieldProps = ICheckboxProps &
  WithFormProps &
  Pick<ControllerRenderProps, 'value' | 'onChange'>;

export const CheckboxField: React.FC<CheckboxFieldProps> = React.forwardRef<
  HTMLInputElement,
  CheckboxFieldProps
>(({ name, value, ...rest }, _ref) => <Checkbox {...rest} checked={value} />);
