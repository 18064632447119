import { FormattedMessage } from 'react-intl';
import { Card, CardTitle, Preloader } from '@smart-kasa/ui';

import { PieChart } from 'components';
import { useGetShiftStatsQuery } from 'services/api/core/stats/api';

export const ShiftStats = () => {
  const { data: stats, isLoading } = useGetShiftStatsQuery();

  return (
    <Card>
      <CardTitle>
        <FormattedMessage id="dashboard.shifts" />
      </CardTitle>

      {isLoading ? <Preloader /> : <PieChart data={stats?.data} />}
    </Card>
  );
};
