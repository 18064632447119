import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { SessionContext } from 'contexts/SessionContext';
import { AdminContextProvider } from 'contexts/AdminContext';

const ProtectedRoute = ({ children }) => {
  const { isLogged } = useContext(SessionContext);
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return <AdminContextProvider>{children}</AdminContextProvider>;
};

export default ProtectedRoute;
