import { SessionContext } from 'contexts/SessionContext';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';

const AuthRoute = ({ children }) => {
  const { isLogged } = useContext(SessionContext);
  const location = useLocation();

  if (isLogged) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthRoute;
