import { FC } from 'react';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { LoginFormSchema } from './LoginFormSchema';
import styles from './LoginForm.module.scss';
import { Button } from '@smart-kasa/ui';

interface ILoginForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const LoginForm: FC<ILoginForm> = ({ defaultValues, onSubmit }) => (
  <Form<any>
    onSubmit={onSubmit}
    form={{
      defaultValues,
      resolver: yupResolver(LoginFormSchema),
    }}
  >
    <Field.Input name="email" label="Електронна пошта" />
    <Field.Password name="password" label="Пароль" type="password" />

    <Button type="submit" color="dark" className={styles.button} width="full">
      Увійти
    </Button>
  </Form>
);
