import { FormattedMessage } from 'react-intl';
import { Card, CardTitle, Preloader } from '@smart-kasa/ui';

import { BarChart } from 'components';
import { useGetBillingStatsQuery } from 'services/api/core/stats/api';

export const BillingStats = () => {
  const { data: stats, isLoading } = useGetBillingStatsQuery();

  return (
    <Card>
      <CardTitle>
        <FormattedMessage id="dashboard.billing" />
      </CardTitle>

      {isLoading ? <Preloader /> : <BarChart data={stats?.data} />}
    </Card>
  );
};
