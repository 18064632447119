import { Routes, Route } from 'react-router-dom';

import { UserList } from './routes/Users/routes/UserList';
import { UserLayout } from './components/UserLayout';
import { UserShow } from './routes/Users/routes/UserShow';
import { TerminalList } from './routes/Terminals/routes/TerminalList';
import { TerminalCreate } from './routes/Terminals/routes/TerminalCreate';
import { EmployeeList } from './routes/Employees/routes/EmployeeList';
import { ShiftList } from './routes/Shifts/routes/ShiftList';
import { ShopList } from './routes/Shops/routes/ShopList';
import { Balance } from './routes/Billing/routes/Balance';
import { Invoices } from './routes/Billing/routes/Invoices';
import { Subscriptions } from './routes/Billing/routes/Subscriptions';
import { TerminalShow } from 'features/pos/routes/Terminals/routes/TerminalShow';

export const Users = () => (
  <Routes>
    <Route index element={<UserList />} />
    <Route path=":id" element={<UserLayout />}>
      <Route index element={<UserShow />} />
      <Route path="terminals" element={<TerminalList />}>
        <Route path="new" element={<TerminalCreate />} />
        <Route path=":terminalId" element={<TerminalShow />} />
      </Route>
      <Route path="employees" element={<EmployeeList />} />
      <Route path="shifts" element={<ShiftList />} />
      <Route path="shops" element={<ShopList />} />
      <Route path="billing">
        <Route path="balance" element={<Balance />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="subscriptions" element={<Subscriptions />} />
      </Route>
    </Route>
  </Routes>
);
