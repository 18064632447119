import { FC, HTMLAttributes } from 'react';
import cs from 'classnames';
import isString from 'lodash/isString';
import { Icon, IconName } from '@smart-kasa/ui';

import { getFileUrl } from 'utils/getFileUrl';
import type { AssetFile } from 'services/api/core/assets/types';

import styles from './Picture.module.scss';

export type PictureColor =
  | 'transparent'
  | 'orange'
  | 'yellow'
  | 'blue'
  | 'cyan'
  | 'grey'
  | 'violet'
  | 'green';

export type PictureSize = 'S' | 'M' | 'L' | 'XL' | 'XXL';

export type PictureProps = Omit<HTMLAttributes<HTMLImageElement>, 'src'> & {
  alt?: string;
  src?: AssetFile | string | null;
  icon?: IconName;
  fill?: PictureColor;
  size?: PictureSize;
};

export const Picture: FC<PictureProps> = ({
  alt,
  src,
  fill = 'blue',
  icon = 'smartKasa',
  size = 'M',
  style,
  className,
  ...rest
}) => (
  <div
    style={style}
    className={cs(styles.picture, [styles[`size${size}`]], [styles[fill]], className, {
      [styles.source]: src,
    })}
  >
    <div className={styles.content}>
      {src ? (
        <img
          className={styles.image}
          src={isString(src) ? src : getFileUrl(src)}
          alt={alt}
          {...rest}
        />
      ) : (
        <Icon name={icon} color="white" size={size === 'XL' ? 'xxx-large' : 'large'} />
      )}
    </div>
  </div>
);
