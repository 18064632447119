import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Button,
  ButtonIcon,
  Card,
  CardTitle,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
} from '@smart-kasa/ui';

import { ITerminal } from 'services/api/core/pos/types';
import { useGetTerminalsQuery } from 'services/api/core/pos/api';
import { TerminalStatus } from 'features/pos/routes/Terminals/components/TerminalStatus';

export const TerminalList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { data: terminals, isLoading } = useGetTerminalsQuery({
    ownerId: id,
    skipPagination: true,
  });

  const columns: IDataTableColumn<ITerminal>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'pos.terminal.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'pos.terminal.name' }),
    },
    {
      key: 'serialNumber',
      title: formatMessage({ id: 'pos.terminal.serial' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'pos.terminal.state' }),
      render: (state) => <TerminalStatus state={state} />,
    },
    {
      key: 'activatedAt',
      title: formatMessage({ id: 'pos.terminal.activatedAt' }),
      render: (activatedAt) => (activatedAt ? <FormattedDate value={activatedAt} /> : '-'),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'pos.terminal.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
    {
      key: 'id',
      render: () => (
        <ButtonIcon>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Card>
      <CardTitle
        actions={
          <Button
            color="dark"
            rightIcon={<Icon name="plus" size="small" />}
            onClick={() => navigate('new')}
          >
            Створити
          </Button>
        }
      >
        Активовані термінали
      </CardTitle>

      <DataTable
        rowIdKey="id"
        data={terminals?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`, { state: { modal: true } })}
        emptyText="Не знайдено жодного терміналу"
      />

      <Outlet />
    </Card>
  );
};
