import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { ITemplateMessage } from './types';

export const templateApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['TemplateMessage'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTemplates: builder.query<CoreResponse<ITemplateMessage[]>, Pagination<ITemplateMessage>>({
        query: (params?) => ({
          url: 'core/manage/template_messages',
          params,
        }),
        providesTags: ['TemplateMessage'],
      }),
      findTemplate: builder.query<CoreResponse<ITemplateMessage>, number>({
        query: (id) => ({
          url: `core/manage/template_messages/${id}`,
        }),
        providesTags: ['TemplateMessage'],
      }),
      createTemplate: builder.mutation<ITemplateMessage, Partial<ITemplateMessage>>({
        query: (body) => ({
          url: `core/manage/template_messages`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['TemplateMessage'],
      }),
      updateTemplate: builder.mutation<
        void,
        Pick<ITemplateMessage, 'id'> & Partial<ITemplateMessage>
      >({
        query: ({ id, ...body }) => ({
          url: `core/manage/template_messages/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['TemplateMessage'],
      }),
      deleteTemplate: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({ url: `core/manage/template_messages/${id}`, method: 'DELETE' }),
        invalidatesTags: ['TemplateMessage'],
      }),
    }),
  });

export const {
  useGetTemplatesQuery,
  useFindTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
} = templateApi;
