import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';

import type { IReceipt, ReceiptsListParams } from './types';

export const receiptApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getReceipts: builder.query<CoreResponse<IReceipt[]>, ReceiptsListParams>({
      query: (params?) => ({ url: 'core/manage/pos/receipts', params }),
    }),
  }),
});

export const { useGetReceiptsQuery } = receiptApi;
