import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Icon, Page, Preloader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useConfirm from 'hooks/useConfirm';
import useToast from 'hooks/useToast';
import {
  useDeleteCoreTerminalMutation,
  useFindCoreTerminalQuery,
  useUpdateCoreTerminalMutation,
} from 'services/api/core/pos/api';

import { CoreTerminalForm } from '../../components/CoreTerminalForm';

export const CoreTerminalEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const { data: template, isLoading } = useFindCoreTerminalQuery(Number(id));
  const [update] = useUpdateCoreTerminalMutation();
  const [remove] = useDeleteCoreTerminalMutation();
  const { confirm } = useConfirm();

  const handleSubmit = useCallback(
    async ({ preferences, ...values }) => {
      const result = await update({ id: Number(id), ...values });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [id, update, toast, formatMessage, navigate]
  );

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm(formatMessage({ id: 'confirmation.delete' }));

    if (!isConfirmed) return;

    remove(Number(id)).then(() => {
      navigate(-1);
    });
  }, [id, confirm, remove, navigate, formatMessage]);

  if (isLoading) return <Preloader />;

  return (
    <Page
      title={<BackLink>Редагування обладнання</BackLink>}
      actions={
        <Button
          color="danger"
          leftIcon={<Icon name="trash" size="medium" />}
          onClick={handleDelete}
        >
          Видалити
        </Button>
      }
    >
      <CoreTerminalForm defaultValues={template?.data} onSubmit={handleSubmit} />
    </Page>
  );
};
