import * as yup from 'yup';

export const PlatformFormSchema = yup.object().shape({
  // name: yup.string().required("Обов'язкове для заповнення поле"),
  // shortName: yup.string().required("Обов'язкове для заповнення поле"),
  // code: yup
  //   .number()
  //   .typeError('Значення повинно бути числом')
  //   .required("Обов'язкове для заповнення поле"),
});

// export interface IAuthPlatform {
//   id: number;
//   name: string;
//   apiKey: string;
//   apiSecret: string;
//   state: AuthPlatformState;
//   stateChangedAt: string;
//   platformTypeId: AuthPlatformType;
//   createdAt: string;
//   updatedAt: string;
// }
