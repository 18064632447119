import { useFormContext } from 'react-hook-form';

export interface IFieldProps {
  children?: any;
}

export const Field: React.FC<IFieldProps> = ({ children }) => {
  const methods = useFormContext();

  return children({ ...methods });
};
