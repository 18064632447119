import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { WithFormProps } from './withForm';
import { RichText, RichTextProps } from 'components/RichText';

export type RichTextFieldProps = RichTextProps &
  WithFormProps &
  Pick<ControllerRenderProps, 'value' | 'onChange'>;

export const RichTextField: React.FC<RichTextFieldProps> = React.forwardRef<
  HTMLInputElement,
  RichTextFieldProps
>(({ name, value, onChange, ...rest }, ref) => (
  <RichText {...rest} ref={ref} value={value} onChange={onChange} />
));
