import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Page, Preloader } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { useGetAllQuery, useUpdateMutation } from 'services/api/core/settings/api';

import { SettingsForm } from './components/SettingsForm';

export const Settings = () => {
  const { data: settings, isLoading } = useGetAllQuery();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const [update] = useUpdateMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await update(values);

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
    },
    [update, toast, formatMessage]
  );

  if (isLoading) return <Preloader />;

  return (
    <Page title="Налаштування">
      <SettingsForm defaultValues={settings?.data} onSubmit={handleSubmit} />
    </Page>
  );
};
