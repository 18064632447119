import { FC, HTMLAttributes } from 'react';
import { useNavigate, Link, LinkProps } from 'react-router-dom';
import { Icon } from '@smart-kasa/ui';
import cs from 'classnames';

import styles from './BackLink.module.scss';

interface IBackLinkProps extends HTMLAttributes<HTMLElement> {}
interface IBackRouteLinkProps extends LinkProps {}

export const BackLink: FC<IBackLinkProps> = ({ className, children, ...rest }) => {
  const navigate = useNavigate();

  return (
    <a className={cs(styles.link, className)} onClick={() => navigate(-1)} {...rest}>
      <Icon name="arrowLeft" className={styles.icon} size="medium" />
      {children}
    </a>
  );
};

export const BackRouteLink: FC<IBackRouteLinkProps> = ({ className, children, ...rest }) => (
  <Link className={cs(styles.link, className)} {...rest}>
    <Icon name="arrowLeft" className={styles.icon} size="medium" />
    {children}
  </Link>
);
