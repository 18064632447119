import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { SettingsFormSchema } from './SettingsFormSchema';

interface ISettingsForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const SettingsForm: FC<ISettingsForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(SettingsFormSchema),
      }}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Field.Input name="smtpAddress" label={formatMessage({ id: 'settings.smtp.address' })} />
          <Field.Input name="smtpPort" label={formatMessage({ id: 'settings.smtp.port' })} />
          <Field.Input name="smtpUser" label={formatMessage({ id: 'settings.smtp.user' })} />
          <Field.Password
            name="smtpPassword"
            label={formatMessage({ id: 'settings.smtp.password' })}
          />
          <Field.Input name="smtpDomain" label={formatMessage({ id: 'settings.smtp.domain' })} />
          <Field.Input name="smtpFrom" label={formatMessage({ id: 'settings.smtp.from' })} />
        </Col>
        <Col span={12}>
          <Field.Tags
            name="feedbackRecivedNotifyUsers"
            label={formatMessage({ id: 'settings.feedback.recipients' })}
          />
          <Field.Input
            name="billingSettleDays"
            label={formatMessage({ id: 'settings.billing.hold-timeout' })}
          />
        </Col>
      </Row>

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
