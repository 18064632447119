import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';

export const statsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getOfflineStats: builder.query<CoreResponse<any[]>, void>({
      query: () => ({ url: 'core/manage/stats/offline_fiscal_receipts' }),
    }),
    getShiftStats: builder.query<CoreResponse<any[]>, void>({
      query: () => ({ url: 'core/manage/stats/shifts_today_counts' }),
    }),
    getBillingStats: builder.query<CoreResponse<any[]>, void>({
      query: () => ({ url: 'core/manage/stats/billing_service_plans' }),
    }),
  }),
});

export const { useGetOfflineStatsQuery, useGetShiftStatsQuery, useGetBillingStatsQuery } = statsApi;
