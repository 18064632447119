import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Preloader } from '@smart-kasa/ui';

const FaqCategoriesRoute = React.lazy(() => import('./routes/FaqCategories/FaqCategories'));
const FaqQuestionsRoute = React.lazy(() => import('./routes/FaqQuestions/FaqQuestions'));

export const Support = () => (
  <React.Suspense fallback={<Preloader />}>
    <Routes>
      <Route path="/categories/*" element={<FaqCategoriesRoute />} />
      <Route path="/questions/*" element={<FaqQuestionsRoute />} />
    </Routes>
  </React.Suspense>
);
