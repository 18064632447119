import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { LOCALE_OPTIONS } from 'constants/locale';
import { TemplateFormSchema } from './TemplateFormSchema';
import { TemplateMessageType } from 'services/api/core/template/enums';

interface ITemplateForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

const TYPES = [
  { value: TemplateMessageType.DRAFT, label: 'Чернетка' },
  { value: TemplateMessageType.INVOICE_ORDER, label: 'Призначення платежу до замовлення' },
  {
    value: TemplateMessageType.INVOICE_SUBSCRIPTION,
    label: 'Призначення платежу до тарифного плану',
  },
  { value: TemplateMessageType.MANAGE_ORDER_MIGRATION, label: 'Зміна статусу замовлення' },
  { value: TemplateMessageType.POS_RECEIPT, label: 'Електронна версія чеку' },
  { value: TemplateMessageType.POS_TRANSACTION_URL, label: 'Посилання для чек' },
  { value: TemplateMessageType.EMAIL_VERIFY, label: 'Верифікація користувача (email)' },
  { value: TemplateMessageType.FEEDBACKS_CREATED, label: 'Нове звернення' },
  { value: TemplateMessageType.USERS_REGISTERED, label: 'Реєстрація користувача' },
  { value: TemplateMessageType.POS_REPORT, label: 'Сформований звіт' },
  { value: TemplateMessageType.SERVICE_PLAN_PAYMENT_NOTIFY, label: 'Попередження тарифного плану' },
];

export const TemplateForm: FC<ITemplateForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(TemplateFormSchema),
      }}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Field.Select
            name="templateTypeId"
            placeholder={formatMessage({ id: 'template.type' })}
            label={formatMessage({ id: 'template.type' })}
            options={TYPES}
          />
        </Col>
        <Col span={12}>
          <Field.Select
            name="locale"
            placeholder={formatMessage({ id: 'template.locale' })}
            label={formatMessage({ id: 'template.locale' })}
            options={LOCALE_OPTIONS}
          />
        </Col>
      </Row>

      <Field.Input name="subject" label={formatMessage({ id: 'template.subject' })} />
      <Field.TextArea name="textContent" label={formatMessage({ id: 'template.text' })} />
      <Field.RichText
        value={defaultValues.htmlContent}
        name="htmlContent"
        label={formatMessage({ id: 'template.html' })}
      />

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
