import { ReactNode, memo } from 'react';
import { Drawer } from '@smart-kasa/ui';

import { SubscriptionFilterForm, ISubscriptionFilterForm } from './SubscriptionFilterForm';

export type SubscriptionFilterProps = ISubscriptionFilterForm & {
  title?: ReactNode;
  show: boolean;
  onClose: () => void;
};

export const SubscriptionFilter: React.FC<SubscriptionFilterProps> = memo(
  ({ title, show, onSubmit, onClose, ...rest }) => {
    const handleSubmit = (data) => {
      onClose();
      onSubmit(data);
    };

    return (
      <Drawer title={title} show={show} onClose={onClose} footer={null}>
        <SubscriptionFilterForm onSubmit={handleSubmit} {...rest} />
      </Drawer>
    );
  }
);
