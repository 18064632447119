import { Routes, Route } from 'react-router-dom';

import { PlatformList } from './routes/PlatformList';
import { PlatformCreate } from './routes/PlatformCreate';
import { PlatformShow } from './routes/PlatformShow';
import { PlatformEdit } from './routes/PlatformEdit';

export const Platforms = () => (
  <Routes>
    <Route index element={<PlatformList />} />
    <Route path="/new" element={<PlatformCreate />} />
    <Route path="/:id" element={<PlatformShow />} />
    <Route path="/:id/edit" element={<PlatformEdit />} />
  </Routes>
);
