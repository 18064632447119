import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Preloader } from '@smart-kasa/ui';

const InventoryUnitsRoute = React.lazy(() => import('./routes/InventoryUnits/InventoryUnits'));

export const Inventory = () => (
  <React.Suspense fallback={<Preloader />}>
    <Routes>
      <Route path="/units/*" element={<InventoryUnitsRoute />} />
    </Routes>
  </React.Suspense>
);
