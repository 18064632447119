import { FC, Fragment } from 'react';
import { UAH } from '@smart-kasa/ui';

import type {
  IReportPaymentMethod,
  ReportPaymentMethodCodes,
} from 'services/api/core/reports/types';

import { ReportRow } from './ReportRow';

interface IPaymentMethod {
  paymentMethod: IReportPaymentMethod;
}

const TITLE_MAPPING: Record<ReportPaymentMethodCodes, string> = {
  CASH: 'Готівка',
  CASHLESS: 'Картка',
};

export const ReportPaymentMethod: FC<IPaymentMethod> = ({ paymentMethod }) => (
  <ReportRow
    title={<Fragment>{paymentMethod.name || TITLE_MAPPING[paymentMethod.code]}</Fragment>}
    value={<UAH value={paymentMethod.total} />}
  />
);
