import { ComponentType } from 'react';
import { useController } from 'react-hook-form';
import { FormElement, IFormElementProps, Label } from '@smart-kasa/ui';

export type WithFormProps = Pick<
  IFormElementProps,
  'label' | 'description' | 'direction' | 'justifyContent' | 'alignItems'
> & {
  name: string;
  required?: boolean;
  className?: string;
};

export const withForm =
  <ComponentProps extends object>(Cmp: ComponentType<ComponentProps>) =>
  ({
    name,
    required,
    label,
    description,
    alignItems,
    direction,
    justifyContent,
    ...rest
  }: ComponentProps & WithFormProps) => {
    const { field, fieldState } = useController({ name });

    return (
      <FormElement
        error={fieldState.error?.message}
        description={description}
        alignItems={alignItems}
        direction={direction}
        justifyContent={justifyContent}
        label={
          <Label htmlFor={name} required={required}>
            {label}
          </Label>
        }
      >
        <Cmp {...(field as any)} {...rest} />
      </FormElement>
    );
  };
