import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AvatarItem, Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form, Picture } from 'components';

import { useGetShopsQuery } from 'services/api/core/shop/api';

export interface IShiftFilterForm {
  defaultValues: any;
  onSubmit: (values) => void;
}

export const ShiftFilterForm: React.FC<IShiftFilterForm> = ({ defaultValues, onSubmit }) => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { data: shops } = useGetShopsQuery({ ownerId: id, skipPagination: true });

  return (
    <Form<any> onSubmit={onSubmit} form={{ defaultValues }}>
      <Row gutter={10}>
        <Col span={8}>
          <Field.Input
            name="fiscalNumber"
            label={formatMessage({ id: 'pos.shift.fiscal-number' })}
          />
        </Col>
        <Col span={8}>
          <Field.Input name="deviceUid" label={formatMessage({ id: 'pos.shift.serial' })} />
        </Col>
        <Col span={8}>
          <Field.Select
            name="shopId"
            label={formatMessage({ id: 'pos.shift.shop' })}
            placeholder={formatMessage({ id: 'pos.shift.shop' })}
            options={shops?.data}
            getOptionLabel={({ title }) => title}
            getOptionValue={({ id }) => id}
            formatOptionLabel={(option) => (
              <AvatarItem
                avatar={<Picture src={option.picture} icon="bank" size="M" fill="grey" />}
                primaryText={option.title}
                secondaryText={option.address?.content}
              />
            )}
          />
        </Col>
      </Row>

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.filter' })}
      </Button>
    </Form>
  );
};
