import { useParams } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { Avatar, Card, Col, Row } from '@smart-kasa/ui';

import { Caption } from 'components';
import { formattedPhone } from 'utils/formattedPhone';
import { useFindUserQuery } from 'services/api/core/user/api';

import styles from './UserShow.module.scss';

export const UserShow = () => {
  const { id } = useParams();
  const { data: record } = useFindUserQuery(Number(id));
  const user = record?.data;

  return (
    <Card>
      <Row gutter={20}>
        <Col className={styles.avatar}>
          <Avatar src={user?.avatar?.url} size="x-large" />
        </Col>
        <Col>
          <Caption name="ПІБ">{user?.name}</Caption>
          <Caption name="Електронна пошта">{user?.email}</Caption>
        </Col>
        <Col>
          <Caption name="Номер телефону">{formattedPhone(user?.phoneNumber)}</Caption>
          <Caption name="Дата реєстрації">
            <FormattedDate value={user?.createdAt} />
          </Caption>
        </Col>
      </Row>
    </Card>
  );
};
