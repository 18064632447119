import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQuery } from 'services/queries/fetchBaseQuery';
import { ILogin, IAdmin, ISession } from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation<ISession, ILogin>({
      query: (session) => ({
        url: `auth/login`,
        method: 'POST',
        body: session,
      }),
      transformResponse: (response: IAdmin, meta) => {
        return {
          session: response,
          token: meta?.response?.headers.get('Authorization'),
        } as ISession;
      },
    }),
  }),
});

export const { useSignInMutation } = authApi;
