import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Card, CardTitle, DataTable, IDataTableColumn, Pagination, Tag } from '@smart-kasa/ui';

import { usePagination } from 'hooks/usePagination';
import { useQuery } from 'hooks/useQuery';
import { useGetBillingInvoicesQuery } from 'services/api/core/invoices/api';
import { IBillingInvoice } from 'services/api/core/invoices/types';
import { BillingInvoiceState, BillingInvoiceType } from 'services/api/core/invoices/enums';

import { BillingTransaction } from '../../components/BillingTransaction';

export const BILLING_INVOICE_STATUS: Record<BillingInvoiceState, { title: string; color: any }> = {
  [BillingInvoiceState.waiting]: {
    title: 'Очікує на сплату',
    color: 'yellow',
  },
  [BillingInvoiceState.paid]: {
    title: 'Транзакція успішна',
    color: 'blue',
  },
  [BillingInvoiceState.past_due]: {
    title: 'Платіж прострочено',
    color: 'green',
  },
  [BillingInvoiceState.cancelled]: {
    title: 'Платіж відхилено',
    color: 'grey',
  },
  [BillingInvoiceState.draft]: {
    title: 'Попередній рахунок',
    color: 'orange',
  },
};

export const Invoices = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { query } = useQuery();
  const { onPageChange } = usePagination();
  const { data: invoices, isLoading } = useGetBillingInvoicesQuery({
    ...query,
    ownerId: id,
    sortMode: 'desc',
    sortColumn: 'id',
  });

  const columns: IDataTableColumn<IBillingInvoice>[] = [
    {
      key: 'number',
      title: formatMessage({ id: 'billing.invoice.number' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'billing.invoice.state' }),
      render: (state) => (
        <Tag color={BILLING_INVOICE_STATUS[state].color}>{BILLING_INVOICE_STATUS[state].title}</Tag>
      ),
    },
    {
      key: 'invoiceTypeId',
      title: formatMessage({ id: 'billing.invoice.type' }),
      render: (invoiceTypeId) =>
        formatMessage({ id: `billing.invoice.type.${BillingInvoiceType[invoiceTypeId]}` }),
    },
    {
      key: 'amount',
      title: formatMessage({ id: 'billing.invoice.amount' }),
      render: (amount) => <FormattedNumber value={amount} style="currency" currency="UAH" />,
    },
    {
      key: 'paidAt',
      title: formatMessage({ id: 'billing.invoice.paid-at' }),
      render: (paidAt) =>
        paidAt ? <FormattedDate value={paidAt} dateStyle="short" timeStyle="short" /> : '-',
    },
    {
      key: 'transaction',
      title: formatMessage({ id: 'billing.invoice.transaction' }),
      render: (transaction) => <BillingTransaction transaction={transaction} />,
    },
  ];

  return (
    <Card>
      <CardTitle>Історія платежів</CardTitle>

      <DataTable
        rowIdKey="id"
        data={invoices?.data}
        columns={columns}
        isLoading={isLoading}
        emptyText="Список порожній"
      />

      <Pagination
        totalItems={invoices?.meta?.totalCount}
        currentPage={invoices?.meta?.currentPage}
        perPage={invoices?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Card>
  );
};
