import { Fragment } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  ButtonIcon,
  Card,
  CardTitle,
  DataTable,
  IDataTableColumn,
  Icon,
  InlineSpace,
  Pagination,
} from '@smart-kasa/ui';

import { usePagination } from 'hooks/usePagination';
import { useQuery } from 'hooks/useQuery';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import { useGetEmployeesQuery } from 'services/api/core/employees/api';
import { IEmployee } from 'services/api/core/employees/types';

export const EmployeeList = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { query } = useQuery();
  const { onPageChange } = usePagination();
  const [copy] = useCopyToClipboard();
  const { data: employees, isLoading } = useGetEmployeesQuery({ ...query, ownerId: id });

  const columns: IDataTableColumn<IEmployee>[] = [
    {
      key: 'firstName',
      title: formatMessage({ id: 'rsn.employee.name' }),
      render: (firstName: string, { lastName }: IEmployee) => (
        <Fragment>
          {firstName} {lastName}
        </Fragment>
      ),
    },
    {
      title: 'Посада',
      key: 'role',
      render: (role: IEmployee['role']) => (role ? role.name : '-'),
    },
    {
      key: 'phoneNumber',
      title: formatMessage({ id: 'rsn.employee.phone' }),
    },
    {
      key: 'pinCode',
      title: formatMessage({ id: 'rsn.employee.pin-code' }),
      render: (pinCode) => (
        <Fragment>
          {pinCode}
          <InlineSpace size={5} />
          <ButtonIcon size="small" onClick={() => copy(pinCode)}>
            <Icon name="copy" size="medium" />
          </ButtonIcon>
        </Fragment>
      ),
    },
    {
      key: 'updatedAt',
      title: formatMessage({ id: 'rsn.employee.activity' }),
      render: (updatedAt) => <FormattedDate value={updatedAt} />,
    },
  ];

  return (
    <Card>
      <CardTitle>Працівники</CardTitle>

      <DataTable
        rowIdKey="id"
        data={employees?.data}
        columns={columns}
        isLoading={isLoading}
        emptyText="Список порожній"
      />

      <Pagination
        totalItems={employees?.meta?.totalCount}
        currentPage={employees?.meta?.currentPage}
        perPage={employees?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Card>
  );
};
