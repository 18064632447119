import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import {
  Button,
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Inline,
  InlineSpace,
  Page,
  Pagination,
  Tag,
} from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';
import useModal from 'hooks/useModal';

import { IBillingSubscription } from 'services/api/core/billing/types';
import { useGetSubscriptionsQuery } from 'services/api/core/billing/api';
import { SubscriptionStatus } from '../../components/SubscriptionStatus';
import { SubscriptionFilter } from '../../components/SubscriptionFilter';

export const BillingSubscriptionList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query, push } = useQuery();
  const { isShowing, showModal, hideModal } = useModal();
  const { onPageChange, onSortChange } = usePagination();
  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery(query);

  const columns: IDataTableColumn<IBillingSubscription>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'billing.subscription.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'plan',
      title: formatMessage({ id: 'billing.subscription.plan' }),
      render: ({ title }, { price }) => (
        <Fragment>
          {title}
          <Hint margin={0}>
            <FormattedNumber value={price} style="currency" currency="UAH" />
          </Hint>
        </Fragment>
      ),
      sort: 'price',
    },
    {
      key: 'owner',
      title: formatMessage({ id: 'billing.subscription.owner' }),
      render: (owner) => owner?.name,
    },
    {
      key: 'state',
      title: formatMessage({ id: 'billing.subscription.state' }),
      render: (state) => <SubscriptionStatus state={state} />,
    },
    {
      key: 'isPayPerTeminal',
      title: formatMessage({ id: 'billing.subscription.pay-per-terminal' }),
      render: (isPayPerTeminal) => <Tag color="grey">{isPayPerTeminal ? 'Так' : 'Ні'}</Tag>,
    },
    {
      key: 'activatedAt',
      title: formatMessage({ id: 'billing.subscription.activated-at' }),
      render: (activatedAt) => <FormattedDate value={activatedAt} />,
      sort: true,
    },
    {
      key: 'activeUntil',
      title: formatMessage({ id: 'billing.subscription.active-until' }),
      render: (activeUntil) => (
        <FormattedDate value={activeUntil} dateStyle="short" timeStyle="short" />
      ),
      sort: true,
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'billing.subscription.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
      sort: true,
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon onClick={() => navigate(`${id}`)}>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page
      title="Підписки"
      actions={
        <Inline>
          <Button
            color="sub-primary"
            rightIcon={<Icon name="filter" size="medium" />}
            onClick={showModal}
          >
            Фільтрація
          </Button>
          <InlineSpace />
          <Button
            color="dark"
            rightIcon={<Icon name="plus" size="small" />}
            onClick={() => navigate('new')}
          >
            Створити
          </Button>
        </Inline>
      }
    >
      <DataTable
        rowIdKey="id"
        data={subscriptions?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`)}
        sortMode={query.sortMode}
        sortColumn={query.sortColumn}
        onSortChange={onSortChange}
      />

      <Pagination
        totalItems={subscriptions?.meta?.totalCount}
        currentPage={subscriptions?.meta?.currentPage}
        perPage={subscriptions?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <SubscriptionFilter
        title="Фільтрація"
        show={isShowing}
        defaultValues={query}
        onSubmit={(values) => push(values)}
        onClose={hideModal}
      />
    </Page>
  );
};
