import { Pagination, CoreResponse } from 'types/api';

import { IWallet, IWalletTransaction } from './types';
import { billingApi } from '../billing/api';

const walletApi = billingApi.injectEndpoints({
  endpoints: (builder) => ({
    getWallets: builder.query<CoreResponse<IWallet[]>, Pagination<IWallet>>({
      query: (params?) => ({ url: 'core/manage/billing/wallets', params }),
    }),
    findWallet: builder.query<CoreResponse<IWallet>, number>({
      query: (id) => ({ url: `core/manage/billing/wallets/${id}` }),
    }),
    updateWallet: builder.mutation<void, Pick<IWallet, 'id'> & IWalletTransaction>({
      query: ({ id, ...wallet }) => {
        return {
          url: `core/manage/billing/wallets/${id}`,
          method: 'PUT',
          body: { id, wallet },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetWalletsQuery, useFindWalletQuery, useUpdateWalletMutation } = walletApi;
