import { Routes, Route } from 'react-router-dom';

import { CoreTerminalList } from './routes/CoreTerminalList';
import { CoreTerminalCreate } from './routes/CoreTerminalCreate';
import { CoreTerminalEdit } from './routes/CoreTerminalEdit';

const FaqCategories = () => (
  <Routes>
    <Route index element={<CoreTerminalList />} />
    <Route path="/new" element={<CoreTerminalCreate />} />
    <Route path="/:id" element={<CoreTerminalEdit />} />
  </Routes>
);

export default FaqCategories;
