import { createApi } from '@reduxjs/toolkit/query/react';

import { authBaseQuery } from 'services/queries/authBaseQuery';

import { AssetFile, AssetFileResponse } from './types';

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    getUploadEndpoint: builder.query<AssetFileResponse, AssetFile>({
      query: (asset) => ({ url: 'core/manage/assets/endpoints', method: 'POST', body: { asset } }),
    }),
  }),
});

export const { useGetUploadEndpointQuery, useLazyGetUploadEndpointQuery } = assetsApi;
