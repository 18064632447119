import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import cs from 'classnames';
import SimpleBar from 'simplebar-react';
import { Layout } from '@smart-kasa/ui';

import { Logo, Navigation } from 'components';
import { Search } from './components/Search';
import { Account } from './components/Account';
import styles from './AppLayout.module.scss';

export const AppLayout = () => {
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(false);
  }, [pathname]);

  return (
    <Layout className={styles.wrapper}>
      <Layout.Sider className={cs(styles.sidebar, { [styles.opened]: isOpened })}>
        <Logo className={styles.logo} />

        <SimpleBar className={styles.scrollbar}>
          <Navigation isOpened={isOpened} onOpenedChange={setIsOpened} className={styles.menu} />
        </SimpleBar>
      </Layout.Sider>

      <Layout className={styles.container}>
        <Layout.Header className={styles.header}>
          <Search />
          <Account />
        </Layout.Header>
        <Layout.Content className={styles.content}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
