import React, { useState } from 'react';
import { Input, Icon, IInputProps, ButtonIcon } from '@smart-kasa/ui';
import styles from './PasswordInput.module.scss';

export const PasswordInput: React.FC<IInputProps> = React.forwardRef<HTMLInputElement, IInputProps>(
  (props, ref) => {
    const [isSecure, setIsSecure] = useState<boolean>(true);

    return (
      <Input
        {...props}
        type={isSecure ? 'password' : 'text'}
        ref={ref}
        suffixIcon={
          <ButtonIcon
            variant="text"
            color="dark"
            onClick={() => setIsSecure(!isSecure)}
            className={styles.button}
          >
            <Icon name={isSecure ? 'eye' : 'eyeSlash'} />
          </ButtonIcon>
        }
      />
    );
  }
);
