import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type {
  BillingSubscriptionFilterParams,
  IBillingPlan,
  IBillingSubscription,
  IStoreService,
} from './types';

export const billingApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['BillingPlan', 'BillingSubscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlans: builder.query<CoreResponse<IBillingPlan[]>, Pagination<IBillingPlan>>({
        query: (params?) => ({ url: 'core/manage/store/service_plans', params }),
        providesTags: ['BillingPlan'],
      }),
      findPlan: builder.query<CoreResponse<IBillingPlan>, number>({
        query: (id) => ({ url: `core/manage/store/service_plans/${id}` }),
        providesTags: ['BillingPlan'],
      }),
      createPlan: builder.mutation<IBillingPlan, Partial<IBillingPlan>>({
        query: (body) => ({ url: 'core/manage/store/service_plans', method: 'POST', body }),
        invalidatesTags: ['BillingPlan'],
      }),
      updatePlan: builder.mutation<void, Pick<IBillingPlan, 'id'> & Partial<IBillingPlan>>({
        query: ({ id, ...body }) => ({
          url: `core/manage/store/service_plans/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['BillingPlan'],
      }),
      deletePlan: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({ url: `core/manage/store/service_plans/${id}`, method: 'DELETE' }),
        invalidatesTags: ['BillingPlan'],
      }),
      getServices: builder.query<CoreResponse<IStoreService[]>, Pagination<IStoreService>>({
        query: (params?) => ({ url: 'core/manage/store/services', params }),
      }),
      findService: builder.query<CoreResponse<IStoreService>, number>({
        query: (id) => ({ url: `core/manage/store/services/${id}` }),
      }),
      createService: builder.mutation<IStoreService, Partial<IStoreService>>({
        query: (body) => ({ url: 'core/manage/store/services', method: 'POST', body }),
      }),
      updateService: builder.mutation<void, Pick<IStoreService, 'id'> & Partial<IStoreService>>({
        query: ({ id, ...body }) => ({
          url: `core/manage/store/services/${id}`,
          method: 'PUT',
          body,
        }),
      }),
      deleteService: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({ url: `core/manage/store/service_plans/${id}`, method: 'DELETE' }),
      }),
      getSubscriptions: builder.query<
        CoreResponse<IBillingSubscription[]>,
        Pagination<IBillingSubscription> & BillingSubscriptionFilterParams
      >({
        query: (params?) => ({ url: 'core/manage/billing/service_plans', params }),
        providesTags: ['BillingSubscription'],
      }),
      findSubscription: builder.query<CoreResponse<IBillingSubscription>, number>({
        query: (id) => ({ url: `core/manage/billing/service_plans/${id}` }),
        providesTags: ['BillingSubscription'],
      }),
      createSubscription: builder.mutation<IBillingSubscription, Partial<IBillingSubscription>>({
        query: (body) => ({ url: 'core/manage/billing/service_plans', method: 'POST', body }),
        invalidatesTags: ['BillingSubscription'],
      }),
      updateSubscription: builder.mutation<
        void,
        Pick<IBillingSubscription, 'id'> & Partial<IBillingSubscription>
      >({
        query: ({ id, ...body }) => ({
          url: `core/manage/billing/service_plans/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['BillingSubscription'],
      }),
      deleteSubscription: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({ url: `core/manage/billing/service_plans/${id}`, method: 'DELETE' }),
        invalidatesTags: ['BillingSubscription'],
      }),
    }),
  });

export const {
  useGetPlansQuery,
  useFindPlanQuery,
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useDeletePlanMutation,
  useGetServicesQuery,
  useGetSubscriptionsQuery,
  useFindSubscriptionQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = billingApi;
