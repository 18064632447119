import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { IAuthPlatform } from './types';

export const platformApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['AuthPlatform'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlatforms: builder.query<CoreResponse<IAuthPlatform[]>, Pagination<IAuthPlatform>>({
        query: (params?) => ({
          url: 'core/manage/auth/platforms',
          params,
        }),
        providesTags: ['AuthPlatform'],
      }),
      findPlatform: builder.query<CoreResponse<IAuthPlatform>, number>({
        query: (id) => ({
          url: `core/manage/auth/platforms/${id}`,
        }),
        providesTags: ['AuthPlatform'],
      }),
      createPlatform: builder.mutation<IAuthPlatform, Partial<IAuthPlatform>>({
        query: (body) => ({
          url: `core/manage/auth/platforms`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['AuthPlatform'],
      }),
      updatePlatform: builder.mutation<void, Pick<IAuthPlatform, 'id'> & Partial<IAuthPlatform>>({
        query: ({ id, ...body }) => ({
          url: `core/manage/auth/platforms/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['AuthPlatform'],
      }),
      deletePlatform: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `core/manage/auth/platforms/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['AuthPlatform'],
      }),
    }),
  });

export const {
  useGetPlatformsQuery,
  useFindPlatformQuery,
  useCreatePlatformMutation,
  useUpdatePlatformMutation,
  useDeletePlatformMutation,
} = platformApi;
