export enum AuthPlatformType {
  web = 0,
  admin = 1,
  terminal = 2,
  mobile = 3,
}

export enum AuthPlatformState {
  active = 0,
  paused = 1,
  deactivated = 2,
}
