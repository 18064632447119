import React, { ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';
import I18n from 'lang';

export interface ITranslationContext {
  locale: string;
  setLocale: (lang: string) => void;
}

export const TranslationContext = React.createContext<ITranslationContext>({
  locale: '',
  setLocale: () => {},
});

export const TranslationContextProvider = ({
  children,
  lang,
  ...rest
}: {
  children: ReactNode;
  lang: string;
}) => {
  const [locale, setLocale] = useState<string>(lang);

  return (
    <TranslationContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      <IntlProvider
        {...rest}
        locale={locale}
        messages={I18n[locale]}
        defaultLocale={process.env.REACT_APP_DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </TranslationContext.Provider>
  );
};

export const LocaleConfig = TranslationContext.Consumer;
