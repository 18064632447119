import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarItem, Hint, InlineSpace, Strong } from '@smart-kasa/ui';

import { ITerminal } from 'services/api/core/pos/types';
import { IAMUser } from 'services/api/core/user/types';
import { SearchableItem } from './SearchableItem';

export type SearchResultsProps = {
  terminals?: ITerminal[];
  users?: IAMUser[];
  onSelect?: () => void;
};

export const SearchResults: FC<SearchResultsProps> = ({ terminals = [], users = [], onSelect }) => {
  const navigate = useNavigate();

  const handleSelect = (url) => {
    navigate(url, { state: { modal: true } });
    onSelect && onSelect();
  };

  return (
    <Fragment>
      {terminals.map((terminal) => (
        <SearchableItem
          key={terminal.id}
          onClick={() => handleSelect(`/users/${terminal.ownerId}/terminals/${terminal.id}`)}
        >
          <Strong>{terminal.serialNumber}</Strong>
          <Hint margin={0}>{terminal.name}</Hint>
        </SearchableItem>
      ))}

      {users.map((user) => (
        <SearchableItem key={user.id} onClick={() => handleSelect(`/users/${user.id}`)}>
          <AvatarItem
            avatar={<Avatar src={user.avatar?.url} name={user.name} size="medium" />}
            primaryText={user.name}
            secondaryText={user.phoneNumber}
          />
          <InlineSpace size={5} direction="vertical" />
        </SearchableItem>
      ))}
    </Fragment>
  );
};
