export enum TemplateMessageType {
  DRAFT = 0,
  INVOICE_ORDER = 1,
  INVOICE_SUBSCRIPTION = 2,
  MANAGE_ORDER_MIGRATION = 3,
  POS_RECEIPT = 4,
  POS_TRANSACTION_URL = 5,
  EMAIL_VERIFY = 6,
  FEEDBACKS_CREATED = 7,
  USERS_REGISTERED = 8,
  POS_REPORT = 9,
  SERVICE_PLAN_PAYMENT_NOTIFY = 10,
}
