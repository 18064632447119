import * as yup from 'yup';

export const LoginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email(
      'Недопустимий формат. Перевірте правильність вводу та спробуйте ще раз'
    )
    .required("Обов'язкове для заповнення поле"),
  password: yup.string().required("Обов'язкове для заповнення поле"),
});
