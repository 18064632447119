import { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Row } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { useGetPlansQuery } from 'services/api/core/billing/api';
import { useLazyGetUsersQuery } from 'services/api/core/user/api';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';
import { SubscriptionFormSchema } from './SubscriptionFormSchema';

interface ISubscriptionForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

const STATUSES = [
  {
    value: BillingSubscriptionState.WAITING,
    label: 'Очікує активації',
  },
  {
    value: BillingSubscriptionState.ACTIVE,
    label: 'Активний',
  },
  {
    value: BillingSubscriptionState.CANCELED,
    label: 'Відключено',
  },
];

export const SubscriptionForm: FC<ISubscriptionForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { data: plans } = useGetPlansQuery({ skipPagination: true });
  const [fetchUsers] = useLazyGetUsersQuery();

  const loadUsers = async (term: string) => {
    const { data: users } = await fetchUsers({ q: term });

    return users?.data;
  };

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(SubscriptionFormSchema),
      }}
    >
      {({ setValue }) => (
        <Fragment>
          <Row gutter={10}>
            <Col span={12}>
              <Field.Autocomplete
                name="owner"
                placeholder="Оберіть користувача"
                label={formatMessage({ id: 'billing.subscription.owner' })}
                disabled={!!defaultValues.id}
                loadOptions={loadUsers}
                getOptionLabel={({ name, phoneNumber }) => `${name} / ${phoneNumber}`}
                getOptionValue={({ id }) => id}
                onChange={(option) => {
                  setValue('owner', option);
                  setValue('ownerId', option.id);
                }}
              />
              <Field.Select
                name="planId"
                label={formatMessage({ id: 'billing.subscription.plan' })}
                placeholder="Оберіть тарифний план"
                options={plans?.data}
                getOptionLabel={(plan) => plan && plan.title}
                getOptionValue={(plan) => plan && plan.id}
              />
              <Field.Select
                name="state"
                placeholder="Оберіть статус"
                label={formatMessage({ id: 'billing.subscription.state' })}
                options={STATUSES}
              />
            </Col>
            <Col span={12}>
              <Field.Input
                type="number"
                name="price"
                label={formatMessage({ id: 'billing.subscription.price' })}
              />
              <Field.Input
                name="daysLeft"
                label={formatMessage({ id: 'billing.subscription.days-left' })}
              />
              <Field.Checkbox
                name="isPayPerTeminal"
                label={formatMessage({ id: 'billing.subscription.pay-per-terminal' })}
              />
            </Col>
          </Row>
          <Button type="submit" color="dark">
            {formatMessage({ id: 'buttons.save' })}
          </Button>
        </Fragment>
      )}
    </Form>
  );
};
