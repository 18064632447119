import { FormHTMLAttributes, ReactNode } from 'react';
import {
  FormProvider,
  useForm,
  UseFormProps,
  SubmitHandler,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

interface FormProps<T extends FieldValues>
  extends Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  form: UseFormProps<T>;
  children: ReactNode | ((methods: UseFormReturn<T>) => ReactNode);
  onSubmit: SubmitHandler<T>;
}

export function Form<T extends FieldValues>({
  form,
  children,
  onSubmit,
  ...rest
}: FormProps<T>): JSX.Element {
  const methods = useForm<T>(form);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...rest}>
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  );
}
