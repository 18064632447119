import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { IAMUser, IAMUsersFilterParams } from './types';

export const userApi = coreApi.enhanceEndpoints({ addTagTypes: ['User'] }).injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<CoreResponse<IAMUser[]>, Pagination<IAMUser> & IAMUsersFilterParams>({
      query: (params?) => ({
        url: 'core/manage/iam/users',
        params,
      }),
      providesTags: ['User'],
    }),
    findUser: builder.query<CoreResponse<IAMUser>, number>({
      query: (id) => ({
        url: `core/manage/iam/users/${id}`,
      }),
      providesTags: ['User'],
    }),
    createUser: builder.mutation<IAMUser, Partial<IAMUser>>({
      query: (body) => ({
        url: 'core/manage/iam/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<void, Pick<IAMUser, 'id'> & Partial<IAMUser>>({
      query: ({ id, ...body }) => ({
        url: `core/manage/iam/users/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `core/manage/iam/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useFindUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
