import { FC, memo } from 'react';
import {
  Collapse,
  CollapseContent,
  CollapseHeader,
  Icon,
  InlineSpace,
  Strong,
} from '@smart-kasa/ui';

import { ShiftFilterForm, IShiftFilterForm } from './ShiftFilterForm';
import styles from './ShiftFilter.module.scss';

export type ShiftFilterProps = IShiftFilterForm & {
  active?: boolean;
};

const ShiftFilter: FC<ShiftFilterProps> = memo(({ active, ...rest }) => (
  <Collapse active={active}>
    <CollapseHeader className={styles.filter}>
      <div className={styles.header}>
        <Icon name="filter" />
        <InlineSpace />
        <Strong>Фільтрація</Strong>
      </div>
    </CollapseHeader>

    <CollapseContent>
      <ShiftFilterForm {...rest} />
    </CollapseContent>
  </Collapse>
));

export { ShiftFilter };
