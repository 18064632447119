export enum TerminalState {
  PENDING = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export enum CoreTerminalState {
  PENDING = 0,
  ACTIVE = 1,
  DELETED = 2,
}
