import { Input, NumberInput, Select, TagsInput, Textarea } from '@smart-kasa/ui';

import { PasswordInput } from '../PasswordInput';
import { withForm } from './withForm';
import { Field as FormField } from './Field';
import { AutocompleteField } from './AutocompleteField';
import { RichTextField } from './RichTextField';
import { CheckboxField } from './CheckboxField';
import { UploaderField } from './UploaderField';
import { DatepickerField } from './DatepickerField';

type FieldType = any;

export const Field = FormField as FieldType;

Field.Input = withForm(Input);
Field.Password = withForm(PasswordInput);
Field.Number = withForm(NumberInput);
Field.TextArea = withForm(Textarea);
Field.RichText = withForm(RichTextField);
Field.Select = withForm(Select);
Field.Autocomplete = withForm(AutocompleteField);
Field.Tags = withForm(TagsInput);
Field.Checkbox = withForm(CheckboxField);
Field.Uploader = withForm(UploaderField);
Field.Datepicker = withForm(DatepickerField);
