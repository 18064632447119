import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Page, Preloader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import useToast from 'hooks/useToast';
import { useFindPlanQuery, useUpdatePlanMutation } from 'services/api/core/billing/api';

import { BillingPlanForm } from '../../components/BillingPlanForm';

export const BillingPlanEdit = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { formatMessage } = useIntl();
  const { data: plan, isLoading } = useFindPlanQuery(Number(planId));
  const [update] = useUpdatePlanMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const result = await update({ id: planId, ...values });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
      navigate(-1);
    },
    [planId, update, toast, formatMessage, navigate]
  );

  if (isLoading) return <Preloader />;

  return (
    <Page title={<BackLink>Редагування тарифного плану</BackLink>}>
      <BillingPlanForm defaultValues={plan?.data} onSubmit={handleSubmit} />
    </Page>
  );
};
