import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { TerminalFilterParams, ITerminal, ICoreTerminal } from './types';

export const posApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['Terminal', 'CoreTerminal'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTerminals: builder.query<
        CoreResponse<ITerminal[]>,
        Pagination<ITerminal> & TerminalFilterParams
      >({
        query: (params) => ({
          url: 'core/manage/pos/terminals',
          params,
        }),
        providesTags: ['Terminal'],
      }),
      findTerminal: builder.query<CoreResponse<ITerminal>, number>({
        query: (id) => ({
          url: `core/manage/pos/terminals/${id}`,
        }),
        providesTags: ['Terminal'],
      }),
      createTerminal: builder.mutation<ITerminal, Partial<ITerminal>>({
        query: (terminal) => ({
          url: 'core/manage/pos/terminals',
          method: 'POST',
          body: { terminal },
        }),
        invalidatesTags: ['Terminal'],
      }),
      updateTerminal: builder.mutation<void, Pick<ITerminal, 'id'> & Partial<ITerminal>>({
        query: ({ id, ...terminal }) => ({
          url: `core/manage/pos/terminals/${id}`,
          method: 'PUT',
          body: { terminal },
        }),
        invalidatesTags: ['Terminal'],
      }),
      deleteTerminal: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `core/manage/pos/terminals/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Terminal'],
      }),
      getCoreTerminals: builder.query<
        CoreResponse<ICoreTerminal[]>,
        Pagination<ICoreTerminal> & TerminalFilterParams
      >({
        query: (params?) => ({
          url: 'core/manage/pos/core_terminals',
          params,
        }),
        providesTags: ['CoreTerminal'],
      }),
      findCoreTerminal: builder.query<CoreResponse<ICoreTerminal>, number>({
        query: (id) => ({
          url: `core/manage/pos/core_terminals/${id}`,
        }),
        providesTags: ['CoreTerminal'],
      }),
      createCoreTerminal: builder.mutation<ICoreTerminal, Partial<ICoreTerminal>>({
        query: (terminal) => ({
          url: 'core/manage/pos/core_terminals',
          method: 'POST',
          body: { terminal },
        }),
        invalidatesTags: ['CoreTerminal'],
      }),
      updateCoreTerminal: builder.mutation<
        void,
        Pick<ICoreTerminal, 'id'> & Partial<ICoreTerminal>
      >({
        query: ({ id, ...terminal }) => ({
          url: `core/manage/pos/core_terminals/${id}`,
          method: 'PUT',
          body: { terminal },
        }),
        invalidatesTags: ['CoreTerminal'],
      }),
      deleteCoreTerminal: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `core/manage/pos/core_terminals/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CoreTerminal'],
      }),
    }),
  });

export const {
  useGetTerminalsQuery,
  useLazyGetTerminalsQuery,
  useFindTerminalQuery,
  useCreateTerminalMutation,
  useUpdateTerminalMutation,
  useDeleteTerminalMutation,
  useGetCoreTerminalsQuery,
  useFindCoreTerminalQuery,
  useCreateCoreTerminalMutation,
  useUpdateCoreTerminalMutation,
  useDeleteCoreTerminalMutation,
} = posApi;
