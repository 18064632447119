import { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cs from 'classnames';
import { Card, Hint, Input, Preloader } from '@smart-kasa/ui';

import useDebounce from 'hooks/useDebounce';
import useOnClickOutside from 'hooks/useClickOutside';

import { useLazyGetTerminalsQuery } from 'services/api/core/pos/api';
import { useLazyGetUsersQuery } from 'services/api/core/user/api';
import { SearchResults } from './SearchResults';
import styles from './Search.module.scss';

export type SearchProps = {
  limit?: number;
};

export const Search: FC<SearchProps> = ({ limit = 5 }) => {
  const [query, setQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const debouncedQuery = useDebounce<string>(query, 500);

  const [loadUsers, { data: users, isFetching: isUsersLoading }] = useLazyGetUsersQuery();
  const [loadTerminals, { data: terminals, isFetching: isTerminalsLoading }] =
    useLazyGetTerminalsQuery();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);
  const handleOpenDropdown = () => setIsOpen(true);
  const handleCloseDropdown = () => {
    setQuery('');
    setIsOpen(false);
  };

  useOnClickOutside(ref, handleCloseDropdown);

  useEffect(() => {
    if (debouncedQuery) {
      loadTerminals({ serialNumber: debouncedQuery, perPage: String(limit) });
      loadUsers({ q: debouncedQuery, perPage: String(limit) });
    }
  }, [debouncedQuery, loadTerminals, loadUsers, limit]);

  return (
    <div className={cs(styles.searchable, { [styles.active]: isOpen })} ref={ref}>
      <Input
        placeholder={formatMessage({ id: 'search.placeholder' })}
        value={query}
        onChange={handleChange}
        onFocus={handleOpenDropdown}
        className={styles.input}
      />

      {isOpen && (
        <Card className={styles.dropdown}>
          {query ? (
            <Fragment>
              {isTerminalsLoading || isUsersLoading ? (
                <Preloader size="small" />
              ) : (
                <SearchResults
                  terminals={terminals?.data}
                  users={users?.data}
                  onSelect={handleCloseDropdown}
                />
              )}
            </Fragment>
          ) : (
            <Hint margin={0}>{formatMessage({ id: 'search.blank' })}</Hint>
          )}
        </Card>
      )}
    </div>
  );
};
