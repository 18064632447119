import '@smart-kasa/ui/dist/esm/bundle.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// TODO: Move global css to 'css-reset' of @smartkasa/ui package
import 'components/global.module.scss';

import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from 'redux/store';
import { SessionContextProvider } from 'contexts/SessionContext';
import { TranslationContextProvider } from 'contexts/TranslationContext';
import { ToastContextProvider } from 'contexts/ToastContext';
import { ConfigContext } from 'contexts/ConfigContext';
import { ConfirmContextProvider } from 'contexts/ConfirmContext';
import Router from 'routes/Router';

const App = () => {
  const { config } = useContext(ConfigContext);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <TranslationContextProvider lang={config.locale}>
            <ToastContextProvider>
              <SessionContextProvider>
                <ConfirmContextProvider>
                  <Router />
                </ConfirmContextProvider>
              </SessionContextProvider>
            </ToastContextProvider>
          </TranslationContextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
