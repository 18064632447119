import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { FeedbackFilterParams, IFeedbackMessage } from './types';

export const feedbackApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query<
      CoreResponse<IFeedbackMessage[]>,
      Pagination<IFeedbackMessage> & FeedbackFilterParams
    >({
      query: (params?) => ({
        url: 'core/manage/feedbacks/messages',
        params,
      }),
    }),
    findMessage: builder.query<CoreResponse<IFeedbackMessage>, number>({
      query: (id) => ({
        url: `core/manage/feedbacks/messages/${id}`,
      }),
    }),
    deleteMessage: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `core/manage/feedbacks/messages/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetMessagesQuery, useFindMessageQuery, useDeleteMessageMutation } = feedbackApi;
