import { useIntl } from 'react-intl';
import { Button } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { useGetPlansQuery } from 'services/api/core/billing/api';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';

export interface ISubscriptionFilterForm {
  defaultValues: any;
  onSubmit: (values) => void;
}

const STATUSES = [
  {
    value: BillingSubscriptionState.WAITING,
    label: 'Очікує активації',
  },
  {
    value: BillingSubscriptionState.ACTIVE,
    label: 'Активний',
  },
  {
    value: BillingSubscriptionState.CANCELED,
    label: 'Відключено',
  },
];

export const SubscriptionFilterForm: React.FC<ISubscriptionFilterForm> = ({
  defaultValues,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { data: plans } = useGetPlansQuery({ skipPagination: true });

  return (
    <Form<any> onSubmit={onSubmit} form={{ defaultValues }}>
      <Field.Select
        name="planId"
        label={formatMessage({ id: 'billing.subscription.plan' })}
        placeholder="Оберіть тарифний план"
        options={plans?.data}
        getOptionLabel={(plan) => plan && plan.title}
        getOptionValue={(plan) => plan && plan.id}
      />
      <Field.Select
        name="state"
        placeholder="Оберіть статус"
        label={formatMessage({ id: 'billing.subscription.state' })}
        options={STATUSES}
      />

      <Button type="submit" color="dark" width="full">
        {formatMessage({ id: 'buttons.filter' })}
      </Button>
    </Form>
  );
};
