import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import humps from 'humps';

import type { HttpAdapter } from 'services/http/types';

import { authBaseQuery } from './authBaseQuery';

const adapter: HttpAdapter = {
  request: humps.decamelizeKeys,
  response: (data) =>
    humps.camelizeKeys(data, (key, convert) => (/^[a-zA-Z0-9_]+$/.test(key) ? convert(key) : key)),
};

export const coreBaseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async ({ body, params, ...args }, api, extraOptions) => {
  const payload = {
    ...args,
    body: adapter.request(body),
    params: adapter.request(params),
  };

  const query = await authBaseQuery(payload, api, extraOptions);
  const result = adapter.response(query);

  if (query.error) {
    return {
      error: {
        status: result.error.status,
        data: result.error.data,
      },
    };
  }

  return result;
};
