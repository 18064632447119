import { FC, Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { Hint, UAH, Strong } from '@smart-kasa/ui';

import type { IReport } from 'services/api/core/reports/types';

import { ReportWrapper } from './ReportWrapper';
import { ReportHeader } from './ReportHeader';
import { ReportTitle } from './ReportTitle';
import { ReportTotals } from './ReportTotals';
import { ReportGroup } from './ReportGroup';
import { ReportHeaderRow } from './ReportHeaderRow';
import { ReportRow } from './ReportRow';
import { ReportShop } from './ReportShop';

export const Report: FC<{ report: IReport }> = ({ report }) => {
  const { payload } = report;

  return (
    <ReportWrapper>
      <ReportHeader>
        <ReportTitle>
          Z-Звіт {payload && payload.fiscalNumber && `/ ФН ${payload.fiscalNumber}`}
        </ReportTitle>
      </ReportHeader>

      {report.shop && <ReportShop shop={report.shop} />}

      {payload.cashierName && <ReportHeaderRow title="Касир:" value={payload.cashierName} />}
      {payload.fiscalNumber && <ReportHeaderRow title="ФН:" value={payload.fiscalNumber} />}
      {payload.registrarFiscalNumber && (
        <ReportHeaderRow title="ФН ПРРО:" value={payload.registrarFiscalNumber} />
      )}
      {report.createdAt && (
        <ReportHeaderRow
          title="Дата та час:"
          value={<FormattedDate value={report.createdAt} dateStyle="short" timeStyle="short" />}
        />
      )}

      {payload.sale && <ReportTotals title="Підсумки реалізації" totals={payload.sale} />}
      {payload.refund && <ReportTotals title="Підсумки повернення" totals={payload.refund} />}
      {payload.cash && <ReportTotals title="Підсумки видачі готівки" totals={payload.cash} />}

      <ReportGroup title="Загальні підсумки">
        <ReportRow
          title={
            <Fragment>
              Службове внесення:
              <Hint margin={0}>Отримання авансу / отримання підкріплення</Hint>
            </Fragment>
          }
          value={<UAH value={payload.serviceInput} />}
        />

        <ReportRow
          title="Службова видача/Інкасація:"
          value={<UAH value={payload.serviceOutput} />}
        />

        <ReportRow
          title={<Strong>Результат дня:</Strong>}
          value={<UAH value={payload.turnoverAmount} />}
        />
        <ReportRow
          title={<Strong>Готівка в касі:</Strong>}
          value={<UAH value={payload.cashAmount} />}
        />
      </ReportGroup>
    </ReportWrapper>
  );
};
