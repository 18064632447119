import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { TerminalFormSchema } from './TerminalFormSchema';

interface ITerminalForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const TerminalForm: FC<ITerminalForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      onSubmit={onSubmit}
      form={{
        defaultValues,
        resolver: yupResolver(TerminalFormSchema),
      }}
    >
      <Field.Input name="name" label={formatMessage({ id: 'pos.terminal.name' })} />

      <Button type="submit" color="dark">
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
